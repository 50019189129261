import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";
import axios from 'axios';
import { Popover } from 'antd';
import NotLogin from '../components/notLogin';

const API_KEY = "AIzaSyCweBBAA0GDdj8rsEicSnFtXCV3_M0eI_M";

const mapContainerStyle = {
  width: "100%",
  height: "80vh",
};

function Maps() {
  const loginemail = Cookies.get("email");
  const loginname = Cookies.get("Name");
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const [kit, setkit] = useState([])

  const [practitioner, setpractitioner] = useState([]);
  const [mark, setmarks] = useState([]);

  useEffect(() => {
    const fetchPractitioners = async () => {
      const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner");
      const result = await response.json();
      setpractitioner(result);


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits")
        .then(response => response.json())
        .then(result => setkit(result))
    };

    fetchPractitioners();
  }, []);

  useEffect(() => {
    getAddressAndMark();
  }, [practitioner]);

  const getAddressAndMark = async () => {
    const markers = [];




    for (const value of practitioner) {
      if (value.address !== undefined && value.location === undefined) {






        //   var show = "green"
        //   // if (!value.assignedKits && value.assignedkits.length > 0) {





        //   //   kit.map((value2, index) => {

        //   //     value.assignedkits.map((value3) => {

        //   //       if (value3 === value2._id) {


        //   //         const today = new Date();
        //   //         const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6);

        //   //         // Get the year and month
        //   //         const year = sixMonthsAgo.getFullYear();
        //   //         const month = String(sixMonthsAgo.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as it's zero-indexed

        //   //         // Format the date in "yyyy-mm" format
        //   //         const formattedDate = `${year}-${month}`;

        //   //         const date = formattedDate.split('-');
        //   //         const timestamp = value2.timestamp

        //   //         // // Extract year and month from timestamp
        //   //         const timestampYearMonth = timestamp.slice(0, 7).split('-');


        //   //         const formattedDateString = formattedDate.replace('-', '');
        //   //         const timestampYearMonthString = timestamp.slice(0, 7).replace('-', '');

        //   //         if (formattedDateString < timestampYearMonthString) {
        //   //           show = "red";
        //   //         }
        //   //       }
        //   //     })


        //   //   })

        //   // } else {
        //   //   show = true
        //   // }





        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value.address)}&key=${API_KEY}`;
        // const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent("karachi")}&key=${API_KEY}`;

        const response = await axios.get(url);
        if (response.data.results.length > 0) {
          const location = response.data.results[0].geometry.location

          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          const urlencoded = new URLSearchParams();
          urlencoded.append("location", JSON.stringify(location));
          urlencoded.append("_id", value._id);

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
          };

          await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addlocation", requestOptions)
            .then((response) => response.json())
            .then((result) => {

              setpractitioner(result)


            })
            .catch((error) => console.error(error));
        } else {
          {
            const location = { lat: 1, lng: 1 }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("location", JSON.stringify(location));
            urlencoded.append("_id", value._id);

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: urlencoded,
              redirect: "follow"
            };

            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addlocation", requestOptions)
              .then((response) => response.json())
              .then((result) => {

                setpractitioner(result)


              })
              .catch((error) => console.error(error));
          }
        }



      } else {

      }
    }


  }
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [seequetionnnnn, setseequetionnnnn] = useState(true)





  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // To show loading state


  const getOrders = async () => {

    const page = 1;
    var totalPages = 2
    var oders = []

    const woocommerceApiUrl = 'https://yourgutmap.co.uk/wp-json/wc/v3/';
    const consumerKey = 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2';
    const consumerSecret = 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8';



    const encodedCredentials = btoa(`${consumerKey}:${consumerSecret}`);
    const headers = {
      'Authorization': `Basic ${encodedCredentials}`,
    };

    try {
      setLoading(true);


      for (var i = 1; i <= totalPages; i++) {


        const response = await axios.get(`${woocommerceApiUrl}orders`, {
          headers,
          params: {
            per_page: 100, // Number of orders per page
            page: i,
          },
        });


        const fetchedOrders = response.data;
        oders.push(...response.data)
        totalPages = parseInt(response.headers['x-wp-totalpages']);






      }



      await setOrders(oders)
      await setLoading(false);

    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
      return [];
    }
  };


  useEffect(() => {
    getOrders();
  }, []);




  const tootttalprice = () => {

    var totalpricec = 0
    orders.forEach((order) => {
      if (selectedMarker.email.toLowerCase() === order.billing.email.toLowerCase()) {

        totalpricec += parseInt(order.total);

      }
    });
    return totalpricec
  }

  return (
    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ?
       <>
        <Navbar />
        <div className='deshboardmain'>
          <Sidemanu />
          <div className='Practitionermainbody'>
            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>
                <FaMapMarkedAlt style={{ width: '50px', height: '50px' }} />
                Practitioner's Location
              </h1>
            </div>





            <div style={{ display: 'flex', marginBottom: '50px' }}>
              {seequetionnnnn ? (<>

                <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Mark By KITS</h4>

                {loading ? (<>  <h4 className='qqqqqqq ' style={{ display: 'flex', margin: "0" }}>Loading...</h4>
                </>) : (<>

                  <h4 className='qqqqqqq ' onClick={() => setseequetionnnnn(!seequetionnnnn)} style={{ display: 'flex', margin: "0" }}>Mark By Orders</h4>
                </>)}



              </>) : (<>

                <h4 className='qqqqqqq ' onClick={() => setseequetionnnnn(!seequetionnnnn)} style={{ display: 'flex', margin: "0" }}> Mark By KITS</h4>
                <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Mark By Orders</h4>



              </>)}

            </div>

            {seequetionnnnn ? (<>
              <div className='header'>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/green-dot.png' /><p>Have Microbiome Kits and Other Kits</p></div>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/purple-dot.png' /><p>Have other Kits but not Microbiome</p></div>

                <div><img src='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' /><p>Only have Microbiome Kits</p></div>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' /><p>Never Bought any Kits</p></div>
              </div>

            </>) : (<>

              <div className='header'>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/green-dot.png' /><p>Active (last 30 days)</p></div>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' /><p>Previously Active (30 days)</p></div>

                <div><img src='http://maps.google.com/mapfiles/ms/icons/purple-dot.png' /><p>Previously Active (90 days)</p></div>

                <div><img src='http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' /><p>Previously Active (6 months)</p></div>
                <div><img src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' /><p>Never Active</p></div>
              </div>

            </>)}

            <LoadScript googleMapsApiKey={API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{ lat: 54.1728691, lng: -0.7705049 }}
                zoom={6}
              >


                {seequetionnnnn ? (<>
                  {practitioner.map((value, index) => {

                    if (value.location !== undefined && value.location.length > 0) {


                      var show = ''

                      // console.log(value.assignedkits)

                      // console.log(value.assignedkits.length)
                      if (value.mbtype && value.mbkits === "0" && value.assignedkits.length === 0) {





                        show = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';


                      } else {

                        if (value.assignedkits.length > 0) {



                          if (value.mbtype && value.mbkits !== "0") {

                            show = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                          } else {
                            show = 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png'

                          }

                        } else {


                          show = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                        }
                      }





                    }
                    return (<>
                      {(value.location !== undefined && value.location.length > 0) && <>



                        <Marker

                          key={index}
                          position={{ lat: value.location[0].lat, lng: value.location[0].lng }}
                          icon={{
                            url: show, // Green icon URL
                            scaledSize: { width: 40, height: 40 }, // Optional: resize the marker
                          }}
                          title={value.name}
                          onClick={() => setSelectedMarker(value)} // Set selected marker on click
                        />
                      </>}
                    </>)
                  })}
                </>) : (<>






                  {practitioner.map((value, index) => {

                    if (value.location !== undefined && value.location.length > 0) {

                      let total = 0;
                      let totalcost = 0;
                      var show = '';
                      let hasRecentOrder6 = false;
                      let hasRecentOrder3 = false;
                      let hasRecentOrder1 = false;

                      const filter = "all"



                      orders.forEach((order) => {
                        if (value.email.toLowerCase() === order.billing.email.toLowerCase()) {
                          total += 1;
                          totalcost += parseInt(order.total);
                          // show = "http://maps.google.com/mapfiles/ms/icons/green-dot.png"; // Show practitioners with orders

                          const orderDate = new Date(order.date_created); // Assuming order has a 'date' field
                          const today = new Date();
                          const sixMonthsAgo = new Date();
                          const threeMonthsAgo = new Date();
                          const oneMonthsAgo = new Date();


                          sixMonthsAgo.setMonth(today.getMonth() - 6);
                          threeMonthsAgo.setMonth(today.getMonth() - 3);
                          oneMonthsAgo.setMonth(today.getMonth() - 1);

                          if (orderDate > sixMonthsAgo) {
                            hasRecentOrder6 = true; // Mark if there is any recent order
                          }
                          if (orderDate > threeMonthsAgo) {
                            hasRecentOrder3 = true; // Mark if there is any recent order
                          }
                          if (orderDate > oneMonthsAgo) {
                            hasRecentOrder1 = true; // Mark if there is any recent order
                          }

                        }

                      });


                      if (total === 0) {
                        show = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
                      }

                      else if (total > 0 && !hasRecentOrder6) {
                        show = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
                      }

                      else if (total > 0 && !hasRecentOrder3) {
                        show = 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png';
                      }

                      else if (total > 0 && !hasRecentOrder1) {
                        show = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
                      } else {
                        show = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

                      }





                    }
                    return (<>
                      {(value.location !== undefined && value.location.length > 0) && <>



                        <Marker

                          key={index}
                          position={{ lat: value.location[0].lat, lng: value.location[0].lng }}
                          icon={{
                            url: show,
                            scaledSize: { width: 40, height: 40 },
                          }}
                          title={value.name}
                          onClick={() => setSelectedMarker(value)}
                        />
                      </>}
                    </>)
                  })}












                </>)}

                {selectedMarker && (
                  <InfoWindow
                    position={{
                      lat: selectedMarker.location[0].lat,
                      lng: selectedMarker.location[0].lng,
                    }}
                    onCloseClick={() => setSelectedMarker(null)} // Close the popup when clicking the "X"
                  >
                    <div>
                      <h3>{selectedMarker.name}</h3>
                      <h4>{selectedMarker.email}</h4>
                      <p>{selectedMarker.address}</p>

                      <p>Microbiome Kits : {selectedMarker.mbkits},  other kits : {selectedMarker.assignedkits.length}</p>
                      {loading ? (<>Order Loading..</>) : (<>
                        <p>Total Orders :  {orders.filter(value => value.billing.email.toLowerCase() === selectedMarker.email.toLowerCase()).length},  Revenue : &pound;{tootttalprice()}</p>
                      </>)}

                      {/* Add any additional information here */}
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </> : <NotLogin />}
    </div>
  );
}

export default Maps;
