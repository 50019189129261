import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../../css/sendemail.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaFileArrowDown } from 'react-icons/fa6';
import { ImCross } from 'react-icons/im';
import ReactQuill from 'react-quill';
import Papa from 'papaparse';
import { ImUpload2, ImUserTie } from 'react-icons/im'
import NotLogin from '../../components/notLogin';
import { SiMinutemailer } from 'react-icons/si';


function Singalemail() {



    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")
    const [subject, setsubject] = useState("")
    const [to, setto] = useState("")
    const [trackemail, settrackemail] = useState(false)
    const [loading, setloading] = useState(false)
    const apiurlforbackend = process.env.REACT_APP_APIURL;

    const [content, setContent] = useState('');

    const quillRef = useRef(null);

    const handleImageUpload = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('image', file);

            try {
                const response = await axios.post(apiurlforbackend + '/uploads', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                const imageUrl = response.data.imageUrl;

                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                quill.insertEmbed(range.index, 'image', imageUrl);
            } catch (error) {
                message.error('Image upload failed');
            }
        };
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        if (!content.trim()) {
            message.error("Please enter the email content");
            return;
        }

        try {
            await axios.post(apiurlforbackend + '/send-singal-email', { to, subject, content, trackemail });
            message.success("Email sent successfully");
        } catch (error) {
            message.error("Failed to send email");
        }
    };

    return (
        <div>
            {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") ?
                <>



                    <Navbar />

                    <div className='deshboardmain'>
                        <Sidemanu />

                        <div className='mainbody' style={{ paddingTop: "20px" }}>


                            <h1
                                style={{
                                    color: "#6E4E9F",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <SiMinutemailer style={{ width: "35px", height: "35px" }} />
                                Send Email
                            </h1>

                            <form onSubmit={sendEmail} className='emailbody' style={{ marginBottom: "25px" }}>

                                {loading ? (<>

                                    <img src='/empty.gif' width={"50%"} />
                                </>) : (<>
                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>To:</h3>
                                        <input required className='formalinput' placeholder='Enter Email' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={to} onChange={(e) => setto(e.target.value)} />
                                        <p style={{ padding: '0', margin: '0', fontSize: '0.8em' }}><strong>NOTE:</strong> If you want to send to multiple emails just put <strong>( , )</strong> at the end you each email and type next email</p>
                                    </div>

                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px" }}>
                                        <h3>Email Subject:</h3>
                                        <input required className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F" }} value={subject} onChange={(e) => setsubject(e.target.value)} />

                                    </div>


                                    <div >

                                        <div className='contantdiv' style={{ color: '#6E4E9F' }}>



                                            <h3>Email Content:</h3>
                                            <div  >
                                                <ReactQuill
                                                    ref={quillRef}
                                                    value={content}
                                                    onChange={setContent}
                                                    modules={{
                                                        toolbar: {
                                                            container: [
                                                                [{ 'header': [1, 2, false] }],
                                                                ['bold', 'italic', 'underline'],
                                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                ['link', 'image']
                                                            ],
                                                            handlers: {
                                                                image: handleImageUpload
                                                            }
                                                        }
                                                    }}
                                                    placeholder="Write your email here..."
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='contantdiv' style={{ color: '#6E4E9F', marginTop: "10px", display: 'flex', flexDirection: 'row !important', gap: '0', alignItems: 'center' }}>
                                        <input checked={trackemail} id='trackemail' type='checkbox' className='formalinput' placeholder='Subject' style={{ borderRadius: "10px", border: "1px solid  #6E4E9F", color: "#6E4E9F", width: '20px', height: '20px' }} onChange={(e) => settrackemail(e.target.checked)} />

                                        <label for="trackemail">Track this Email</label >

                                    </div>

                                    <div className='contantdiv' style={{ marginBottom: "10px" }}>
                                        <button type='submit' className='button' style={{ color: "#dcd2fc", padding: "7px 25px", borderRadius: "6px", background: "#6e4f9f" }}>SEND EMAIL</button>
                                    </div>
                                </>)}
                            </form>



                        </div>

                    </div>



                </> : <NotLogin />}
        </div>
    )
}

export default Singalemail
