import React from 'react'

function Massemail() {
  return (
    <div>
      Massemail
    </div>
  )
}

export default Massemail
