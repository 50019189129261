import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/deshboard.css"
import { BiSolidAddToQueue } from "react-icons/bi"
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { MdAssignmentReturn, MdAttachEmail, MdEditSquare, MdOutlinePayment, MdOutlinePayments, MdOutlinePendingActions } from "react-icons/md"
import { loadStripe } from '@stripe/stripe-js';
import { AiOutlineClose } from "react-icons/ai"
import { IoIosArrowDropdown } from "react-icons/io";
import Cookies from 'js-cookie';
import { AutoComplete, Button, Dropdown } from 'antd';
import Item from 'antd/es/list/Item';
import { FaKitMedical } from 'react-icons/fa6';
import { GiFirstAidKit } from 'react-icons/gi';
import { FaUser } from 'react-icons/fa';
import { TbGitBranchDeleted, TbReportMoney } from "react-icons/tb";
import { SiSemaphoreci } from "react-icons/si";
import NotLogin from '../components/notLogin';




function Microkit() {



  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")

  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState("addkit")
  const [addkit2, setaddkit2] = useState("")
  const [addkit2p, setaddkit2p] = useState("")
  const [addkit, setaddkit] = useState([])
  const [kit, setkit] = useState([])





  const [kitid, setkitid] = useState("")
  const [kitiddb, setkitiddb] = useState("")
  const [kittype, setkittype] = useState("")
  const [kitdetails, setkitdetails] = useState([])

  const [practitioner, setpractitioner] = useState([])
  const [practitionerid, setpractitionerid] = useState("")
  const [ackKit, setackKit] = useState([])


  const Addkit = async (e) => {
    await e.preventDefault()


    await setaddkit([...addkit, { Kit: addkit2, Kitprice: addkit2p, Kittype: kittype }]);
    setaddkit2("")
    setaddkit2p("")
    setkittype("")
    console.log(addkit)
  }

  const RemoveItemByIndex = (index) => {
    // Create a new array without the item at the specified index
    const updatedAddkit = addkit.filter((_, i) => i !== index);
    setaddkit(updatedAddkit);
  };






  const sendnewkittobackend = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json"); // Change the content type to JSON

    // Create an array


    // Convert the array to a JSON string
    var jsonData = JSON.stringify(addkit);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: jsonData, // Send the JSON data
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addkit", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result),

        setpopup(false),
        setaddkit([]),
        setaddkit2(""),


      )
      .catch(error => console.log('error', error));





  }



  const assignkittopra = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("kitID", kitiddb);
    urlencoded.append("userid", practitionerid);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/assignkittopra", requestOptions)
      .then(response => response.json())
      .then(result => setkit(result),

        setpopup(false),
        setaddkit([]),
        setaddkit2(""),
      )
      .catch(error => console.log('error', error));


  }


  const [totalPages, settotalPages] = useState(1);

  useEffect(() => {

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/testingss", requestOptions)
        .then(response => response.json())
        .then(result => {
          setkit(result.data)
          settotalPages(result.meta.last_page)
          renderPageNumbers()
        })



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallemaidata", requestOptions2)
        .then(response => response.json())
        .then(result => setackKit(result))





















    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }






  }, [])


  // const Kit = [
  //   { name: 'ali', age: 25 },
  //   { name: 'ahmed', age: 15 },
  //   { name: 'ahmed', age: 15 },
  //   { name: 'samoo', age: 22 },
  // ];

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = (e) => {
    e.preventDefault()






    const intttt = parseInt(searchTerm)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = kit.filter((item) =>
      item.kit_code === intttt
    );

    // setSuggestions(filteredSuggestions);

    setkit(filteredSuggestions)
    setsearchdone(true)

  };

  var options = [];

  const dooption = () => {
    kit.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kit_code)
      options.push({ value: value.kit_code })


    })


  }






  const [searchdone, setsearchdone] = useState(false)



  const clearsearch = () => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))





    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {

      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));




    }

    setsearchdone(false)

  }































































  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (pageNumber) => {
    await setCurrentPage(pageNumber);




    var urlencoded2 = new URLSearchParams();
    urlencoded2.append("pageNumber", pageNumber);


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded2,
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/microkitsbypage", requestOptions)
      .then(response => response.json())
      .then(result => {
        setkit(result.data)


      })









    await renderPageNumbers()
  };







  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, currentPage - maxPageToShow);
    const endPage = Math.min(totalPages, currentPage + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? 'activeeee' : ''}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };




  var sno1 = 1
  var sno2 = 1


  return (<>
    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
     <>


      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        <div className='adminkitmainbody'>
          <div className='header'>
            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaKitMedical style={{ width: '50px', height: '50px' }} />Microbiome kits</h1>
            {/* {dooption()}
            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
               
                <AutoComplete
                  type="number"
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Search by Kit ID"
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //  console.log(kitss) 
                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>
            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>} */}
          </div>



          {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>



            {popup === true && <>
              <div onClick={() => {
                setpopup(false)
                setaddkit([])
                setaddkit2("")
                setpopup2("addkit")
              }
              } className='popupbg'></div>
              <div className='popup'>





                {popup2 === "kitdetails" && <>

                  <div className='header' >
                    <h2>Kits Details</h2>
                  </div>
                  <div className='kitdetailsmain' >




                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '30px', height: '30px' }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {kitdetails.kit_code} </h3>
                    </div>


                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaUser style={{ width: '30px', height: '30px' }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {kitdetails.practitioner ? kitdetails.practitioner.name : 'No Practitioner'}
                    </h3></div>


                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdAttachEmail style={{ width: '30px', height: '30px' }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>    {kitdetails.practitioner ? kitdetails.practitioner.email : 'No Practitioner'} </h3>
                    </div>


                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '30px', height: '30px' }} /> Status</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {kitdetails.status}


                    </h3></div>


                


                    










                  </div>
                </>}


                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup(false)
                    setaddkit([])
                    setaddkit2("")
                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>


                </div>

              </div>
            </>}
          </>}




















          {kit.length !== 0 && <>

            <div className="pagination">
              {currentPage > 1 && (
                <span onClick={() => handlePageClick(currentPage - 1)}>&lt;&lt;</span>
              )}
              {renderPageNumbers()}
              {currentPage < totalPages && (
                <span onClick={() => handlePageClick(currentPage + 1)}>&gt;&gt;</span>
              )}
            </div>


            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>
                  <th>Kit ID</th>
                  <th>Assigned to</th>
                  {/* <th>Type</th> */}
                  <th>Status</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>


                {kit.map((item, index) => (<>

                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.kit_code}</td>
                    {item.practitioner ? (
                     <td style={{ display: "flex",flexDirection:'column',textAlign:'left', }}>

                        <td style={{ border: "none", width: '50%' }}>{item.practitioner.name} </td>
                        <td style={{ border: "none", width: '50%' }}>{item.practitioner.email}</td>

                      </td>
                    ) : (
                      <> Not Assigned </>
                    )}

                    <td>{item.status}</td>
                    <td>
                      <button className='button'
                        onClick={() => {
                          setpopup(true)
                          setpopup2("kitdetails")
                          setkitdetails(item)
                        }}

                      > Details</button>
                    </td>
                  </tr>




                </>))}





              </tbody>
            </table>


          </>}

          {kit.length === 0 && <>

            <img alt='' src='/empty.gif' width={"40%"} />
          </>}

        </div>


      </div>
    </> : <NotLogin/>} </>)
}

export default Microkit