import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "../css/viewreport.css"
// import { image } from 'html2canvas/dist/types/css/types/image';
// import { CSVLink } from "react-csv"
import html2pdf from 'html2pdf.js';


function Viewreport() {

  const { id } = useParams();
  const [isPdfMode, setIsPdfMode] = useState(false);
  const [kit, setkit] = useState([]);
  const [Result, setResult] = useState([]);

  const contentRef = useRef();
  const contentRef2 = useRef();



  const handleDownloadPDF = async () => {
    await setIsPdfMode(true)


    const content = await document.getElementById('ali'); // Replace with the ID of the content you want to export


    const opt = {
      margin: 0,
      filename: 'my_webpage.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const seee = html2pdf().from(content).set(opt).outputPdf();



    seee.save("YourGutMap IgG FS 100 - " +kit.patientName + ".pdf")
    await setIsPdfMode(false)


  };








  const getdata = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions)
      .then(response => response.json())
      .then(result => {
        setkit(result)
        setResult(result.result)

      })
      .catch(error => console.log('error', error));


  }



  useEffect(() => {


    getdata()
    // sumofresult()

  }, [])


  const sumofresult = () => {

    var sum = 0;
    Result.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })
    // console.log(sum
    return sum;
  }

  const sum2 = () => {

    var sum = 0;
    Result.map((value, index) => {
      if (value <= 0) { sum = sum + 0 }
      else { sum = sum + Math.round(value) }
    })


    var sum2 = ''

    const xMin = 100;
    const xMax = 400;
    const valueMin = -40;
    const valueMax = 40;
    var refValue = 0

    if (sum <= 100) { refValue = -45 } else if (sum >= 400) { refValue = 45 } else { refValue = (sum - xMin) / (xMax - xMin) * (valueMax - valueMin) + valueMin; }




    sum2 = (refValue.toString() + "%")
    return sum2;
  }












  var valuemorethen10 = []
  var value5to5 = []





  const sorting = () => {


    value5to5.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });



    valuemorethen10.sort(function (a, b) {
      return b.value - a.value; // Compare in descending order
    });


  }

  return (<>

    {kit.length !== 0 && <>

      <div>
        <button className='downloadbuttonviewreport' onClick={handleDownloadPDF}>Download</button>
      </div>
      <div id='ali' >

        {/* page 1 */}
        <div ref={contentRef} className={isPdfMode ? 'pdfpage' : 'screen-display'} >
          <img alt='' src='/g1.png' className='uperimg' />
          <div className='pageecontant'>

            <img alt='' src='/logo.png' className='logoimg' />

          </div>
          <img alt='' src='/g2.png' width={"100%"} className='uperimg' />
        </div>

        {/* page 2 */}
        <div ref={contentRef2} className={isPdfMode ? 'pdfpage' : 'screen-display2'} >
          <img alt='' src='/g1.png' className='uperimg' />
          <div className='pageecontant'>

            <img alt='' src='/logo.png' className='logoimg2' />

            <div className='info'>
              <div style={{ display: 'flex', gap: "10px" }}>  <h2 style={{ color: '#6E4E9F' }}> Patient Name : </h2> <h2 style={{ color: '#6799C6' }}> {" "} {kit.patientName}</h2> </div>
              <div style={{ display: 'flex', gap: "10px" }}>    <h2 style={{ color: '#6E4E9F' }}>Sample ID : </h2> <h2 style={{ color: '#6799C6' }}>{kit.kitid}</h2> </div>
              <div style={{ display: 'flex', gap: "10px" }}>   <h2 style={{ color: '#6E4E9F' }}>Sample Date : </h2> <h2 style={{ color: '#6799C6' }}>{kit.SampleDate}</h2> </div>
            </div>

            <div className='info' style={{ backgroundColor: '#6E4E9F' }} >
              <h3 style={{ color: 'white' }}>Total IgG Immune Load </h3>
              <p style={{ color: 'white' }} >Your total IgG reactivity shows the current immune load your body is experiencing with your current
                diet. Research shows by following your IgG guided dietary changes, total immune load can be
                reduced.</p>

              <div className='page2leveldiv' >



                <h1 style={{ fontSize: '300%', color: '#6799C6' }}>Food Sensitivity Screen 100</h1>



              </div>
            </div>


            <div className='info' style={{ color: '#6E4E9F' }}>
              <p>Your food specific IgG antibody diet guide shows IgG reactions and cross reactions to foods and drinks.
                <br />   <br />  This information can be used by a qualified healthcare practitioner to adjust your diet. Any changes in diet including the
                removal of foods should be overseen by a qualified professional, to prevent nutrient deficiencies.
                <br />   <br />  Please note: a food specific IgG antibody test does not analyse classical allergies, where IgE antibodies are involved. Food
                specific IgG antibody testing cannot diagnose conditions such as Coeliac Disease, or Lactose Intolerance. Any pre existing
                allergies or negative reactions to foods should be discussed with your health practitioner.
                YourGutMap defines Food Sensitivity as a food specific IgG antibody reaction. This is not a diagnostic test</p>
            </div>

          </div>
          <img alt='' src='/g2.png' className='uperimg' />
        </div>


        {/* page 3 */}
        <div ref={contentRef} className='pdfpage222'>
          <img alt='' src='/g1.png' className='uperimg' style={{ height: "180px" }} />
          <div className='pageecontant2'>

            <div className='info' >
              <h1 style={{ color: '#6799C6' }}>{kit.patientName}</h1>
              <h3 style={{ color: '#6E4E9F' }}>YourGutMap IgG Food Sensitivity Screen</h3>


              <div className={isPdfMode ? "indicatorp" : "indicator"}>

                <div style={{ backgroundColor: '#d2393f' }}>Elevated {'>'} 10 IgG AU/ml</div>
                <div style={{ backgroundColor: '#ecca99' }}>Borderline 5 - 9 IgG AU/ml</div>
                <div style={{ backgroundColor: '#4280b8' }}>Normal {'<'} 5 IgG AU/ml</div>

              </div>


              <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>


                {Result.map((item, index) => {

                  var value
                  var foodname = "";

                  if (index === 0) { foodname = "Barley" }
                  if (index === 1) { foodname = "Gluten (Gliadin)" }
                  if (index === 2) { foodname = "Wheat " }
                  if (index === 3) { foodname = "White Rice" }
                  if (index === 4) { foodname = "Oat" }
                  if (index === 5) { foodname = "Apple" }
                  if (index === 6) { foodname = "Apricot" }
                  if (index === 7) { foodname = "Banana" }
                  if (index === 8) { foodname = "Blackberry" }
                  if (index === 9) { foodname = "Blueberry" }
                  if (index === 10) { foodname = "Black Olive " }
                  if (index === 11) { foodname = "Cantaloupe Melon" }
                  if (index === 12) { foodname = "Cherry" }
                  if (index === 13) { foodname = "Cranberry" }
                  if (index === 14) { foodname = "Grape Red" }
                  if (index === 15) { foodname = "Grape White" }
                  if (index === 16) { foodname = "Grapefruit" }
                  if (index === 17) { foodname = "Honeydew Melon" }
                  if (index === 18) { foodname = "Lemon" }
                  if (index === 19) { foodname = "Lime" }
                  if (index === 20) { foodname = "Lychee" }
                  if (index === 21) { foodname = "Mango" }
                  if (index === 22) { foodname = "Nectarine " }
                  if (index === 23) { foodname = "Green Olive" }
                  if (index === 24) { foodname = "Orange" }
                  if (index === 25) { foodname = "Peach" }
                  if (index === 26) { foodname = "Pear" }
                  if (index === 27) { foodname = "Persian Melon " }
                  if (index === 28) { foodname = "Pineapple" }
                  if (index === 29) { foodname = "Raspberry" }
                  if (index === 30) { foodname = "Strawberry" }
                  if (index === 31) { foodname = "Tangerine " }
                  if (index === 32) { foodname = "Watermelon" }
                  if (index === 33) { foodname = "Asparagus" }
                  if (index === 34) { foodname = "Avocado" }
                  if (index === 35) { foodname = "Beetroot " }
                  if (index === 36) { foodname = "Broccoli" }
                  if (index === 37) { foodname = "Brussel Sprouts " }
                  if (index === 38) { foodname = "Carrot" }
                  if (index === 39) { foodname = "Cauliflower" }
                  if (index === 40) { foodname = "Celery " }
                  if (index === 41) { foodname = "Chick Pea" }
                  if (index === 42) { foodname = "Cow Pea " }
                  if (index === 43) { foodname = "Cucumber" }
                  if (index === 44) { foodname = "Mushroom" }
                  if (index === 45) { foodname = "Garden Pea" }
                  if (index === 46) { foodname = "Peppers" }
                  if (index === 47) { foodname = "Green Cabbage" }
                  if (index === 48) { foodname = "Iceberg Lettuce" }
                  if (index === 49) { foodname = "Kale " }
                  if (index === 50) { foodname = "Maize " }
                  if (index === 51) { foodname = "Pumpkin " }
                  if (index === 52) { foodname = "Rocket " }
                  if (index === 53) { foodname = "Spinach" }
                  if (index === 54) { foodname = "Swedish Turnip / Swede " }
                  if (index === 55) { foodname = "Sweetcorn" }
                  if (index === 56) { foodname = "Sweet Potato" }
                  if (index === 57) { foodname = "Tomato" }
                  if (index === 58) { foodname = "White Onion" }
                  if (index === 59) { foodname = "White Potato" }
                  if (index === 60) { foodname = "Almond" }
                  if (index === 61) { foodname = "Brazil Nut" }
                  if (index === 62) { foodname = "Cashew Nut" }
                  if (index === 63) { foodname = "Cocoa Bean" }
                  if (index === 64) { foodname = "Coconut" }
                  if (index === 65) { foodname = "Flax Seed" }
                  if (index === 66) { foodname = "Green Bean" }
                  if (index === 67) { foodname = "Hazlenut" }
                  if (index === 68) { foodname = "Haricot Bean" }
                  if (index === 69) { foodname = "Kidney Bean" }
                  if (index === 70) { foodname = "Peanut" }
                  if (index === 71) { foodname = "Pistachio" }
                  if (index === 72) { foodname = "Sesame Seed" }
                  if (index === 73) { foodname = "Soybean" }
                  if (index === 74) { foodname = "Sunflower Seed" }
                  if (index === 75) { foodname = "Vanilla Bean" }
                  if (index === 76) { foodname = "Walnut" }
                  if (index === 77) { foodname = "Egg White" }
                  if (index === 78) { foodname = "Egg Yolk" }
                  if (index === 79) { foodname = "Goat Milk" }
                  if (index === 80) { foodname = "Whole Egg" }
                  if (index === 81) { foodname = "Whole Milk (cow)" }
                  if (index === 82) { foodname = "Cinnamon" }
                  if (index === 83) { foodname = "Garlic" }
                  if (index === 84) { foodname = "Ginger" }
                  if (index === 85) { foodname = "Beef" }
                  if (index === 86) { foodname = "Chicken" }
                  if (index === 87) { foodname = "Lamb" }
                  if (index === 88) { foodname = "Pork" }
                  if (index === 89) { foodname = "Turkey" }
                  if (index === 90) { foodname = "Cod" }
                  if (index === 91) { foodname = "Crab" }
                  if (index === 92) { foodname = "Lobster" }
                  if (index === 93) { foodname = "Mackerel" }
                  if (index === 94) { foodname = "Salmon" }
                  if (index === 95) { foodname = "Sardine" }
                  if (index === 96) { foodname = "Shrimp" }
                  if (index === 97) { foodname = "Tuna" }
                  if (index === 98) { foodname = "Baker's Yeast" }
                  if (index === 99) { foodname = "Brewer's Yeast" }




                  if (item < 0) { value = 0; } else { value = Math.round(item) }

                  if (value >= 10) {
                    valuemorethen10.push({ value: value, foodname: foodname });
                  } else if (value >= 5 && value <= 9) {
                    value5to5.push({ value: value, foodname: foodname });
                  }
                  return (<>

                    {isPdfMode === true && <>

                      {index === 0 && <>  <h3 style={{ width: '100%' }}>Grains & Staples containing Gluten</h3>   </>}

                      {index === 3 && <>  <h3 style={{ width: '100%', marginTop: "35px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "35px" }}>Fruits</h3>   </>}

                      {index === 33 && <>  <h3 style={{ width: '100%', marginTop: "26px" }}>Vegetables</h3>   </>}
                      {index === 60 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}
                      {index === 60 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                      {index === 60 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Nuts, Seeds & Beans</h3>   </>}
                      {index === 77 && <>  <div style={{ marginBottom: '50px' }}></div> </>}
                      {index === 77 && <>  <h3 style={{ width: '100%', marginTop: "4px" }}>Dairy & Eggs</h3>   </>}



                      {index === 82 && <>  <div style={{ marginBottom: '100px' }}></div> </>}
                      {index === 82 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Herbs, Spices & Oils</h3>   </>}





                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "-20px" }}>Meat & Fish</h3>   </>}


                      {index === 180 && <>  <div style={{ marginBottom: '270px' }}></div> </>}




                      {index === 98 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}






                      {index === 21 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}


                    </>}




                    {isPdfMode === false && <>

                      {index === 0 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Grains & Staples containing Gluten</h3>   </>}



                      {index === 3 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Grains & Staples non Gluten</h3>   </>}

                      {index === 5 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Fruits</h3>   </>}

                      {index === 33 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Vegetables</h3>   </>}

                      {index === 60 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Nuts, Seeds & Beans</h3>   </>}

                      {index === 77 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Dairy & Eggs</h3>   </>}


                      {index === 82 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Herbs, Spices & Oils</h3>   </>}





                      {index === 85 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Meat & Fish</h3>   </>}





                      {index === 98 && <>  <h3 style={{ width: '100%', marginTop: "50px" }}>Others</h3>   </>}





                    </>}







                    <div className={isPdfMode ? "boxxp" : "boxx"}>
                      {value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{value}</div></>}
                      {(value >= 5 && value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{value}</div></>}
                      {value < 5 && <>  <div className='number' style={{ backgroundColor: '#4280b8' }}>{value}</div></>}
                      <div className='name'><h4>{foodname}</h4></div>
                    </div>

                    {isPdfMode === true && <>       {index === 20 && <>  <div style={{ marginBottom: '8px' }}></div> </>}</>}
                    {isPdfMode === true && <>       {index === 20 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}





                    {isPdfMode === true && <>        {index === 84 && <>  <div style={{ marginBottom: '65px' }}></div> </>}</>}

                    {isPdfMode === true && <>         {index === 84 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}

                    {isPdfMode === true && <>    {index === 84 && <>  <img alt='' src='/g1.png' className='uperimgp' /></>}</>}


                    {isPdfMode === true && <>        {index === 99 && <>  <div style={{ marginBottom: '320px' }}></div> </>}</>}

                    {isPdfMode === true && <>         {index === 99 && <>  <img alt='' src='/g2.png' className='uperimgp' /></>}</>}


                    {/* {index === 209 && <>  <div style={{marginBottom:'365px'}}></div> </>} */}

                  </>)
                })}





              </div>


            </div>

          </div>



          {/* <img alt='' src='/g2.png' className='uperimg' /> */}



          {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}

        </div>


        {/* <div ref={contentRef} className='pdfpage222' style={{ backgroundColor: "yellowgreen" }}></div>
        <div ref={contentRef} className='pdfpage222' style={{ backgroundColor: "#435" }}></div>
        <div ref={contentRef} className='pdfpage222' style={{ backgroundColor: "#ftdf" }}></div>
 */}


        {/* page 4 */}



        {isPdfMode === false && <>




          <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

            {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
            <img alt='' src='/g1.png' className='uperimg' />
            <div className='pageecontant'>

              <div className='info'>
                <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                {sorting()}

                <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                  <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                  {valuemorethen10.map((item2, index2) => {


                    return <>

                      <div className={isPdfMode ? "boxxp" : "boxx"}>
                        {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                        {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                        {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                        <div className='name'><h4>{item2.foodname}</h4></div>
                      </div>

                    </>

                  }
                  )}



                  <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                  {value5to5.map((item2, index2) => {


                    return <>

                      <div className={isPdfMode ? "boxxp" : "boxx"}>
                        {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                        {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                        {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                        <div className='name'><h4>{item2.foodname}</h4></div>
                      </div>

                    </>

                  }
                  )}







                </div>


              </div>




            </div>
            <img alt='' src='/g2.png' className='uperimg' />
          </div>



        </>}








        {isPdfMode === true && <>



          {/* page 4 */}



          {value5to5.length + valuemorethen10.length <= 32 && <>




            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>



          </>}



          {/* page 4 */}

          {value5to5.length + valuemorethen10.length > 32 && <>
            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  <h1 style={{ width: '100%', marginTop: "0px", textAlign: "center" }}>Your Reactive Foods & Drinks</h1>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>
                    <p style={{ width: '100%', textAlign: "center", backgroundColor: '#d2393f', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Elevated {">"} 10 IgG AU/ml</p>

                    {valuemorethen10.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}











                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>




          </>}




          {/* page 4 */}
          {value5to5.length + valuemorethen10.length > 32 && <>
            <div ref={contentRef2} className={isPdfMode ? 'pdfpage4' : 'screen-display'}  >

              {isPdfMode === false && <>  <img alt='' src='/g2.png' className='uperimg' /></>}
              <img alt='' src='/g1.png' className='uperimg' />
              <div className='pageecontant'>

                <div className='info'>
                  {sorting()}

                  <div className={isPdfMode ? "calcuresultp" : "calcuresult"}>



                    <p style={{ width: '100%', marginTop: '60px', textAlign: "center", backgroundColor: '#ecca99', borderRadius: '50px', padding: '10px 0', color: '#fff' }}>Borderline 5 - 9 IgG AU/ml</p>

                    {value5to5.map((item2, index2) => {


                      return <>

                        <div className={isPdfMode ? "boxxp" : "boxx"}>
                          {item2.value >= 10 && <>   <div className='number' style={{ backgroundColor: '#d2393f' }}>{item2.value}</div></>}
                          {(item2.value >= 5 && item2.value <= 9) && <>  <div className='number' style={{ backgroundColor: '#ecca99' }}>{item2.value}</div></>}
                          {item2.value < 5 && <>   <div className='number' style={{ backgroundColor: '#4280b8' }}>{item2.value}</div></>}
                          <div className='name'><h4>{item2.foodname}</h4></div>
                        </div>

                      </>

                    }
                    )}







                  </div>


                </div>




              </div>
              <img alt='' src='/g2.png' className='uperimg' />
            </div>

          </>}



        </>}




      </div>

    </>}

  </>)
}

export default Viewreport