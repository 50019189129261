import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/deshboard.css"
import { BiSolidAddToQueue } from "react-icons/bi"
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { MdAssignmentReturn, MdAttachEmail, MdEditSquare, MdOutlinePayment, MdOutlinePayments, MdOutlinePendingActions, MdOutlineQueryStats } from "react-icons/md"
import { loadStripe } from '@stripe/stripe-js';
import { AiOutlineClose } from "react-icons/ai"
import { IoIosArrowDropdown } from "react-icons/io";
import Cookies from 'js-cookie';
import { AutoComplete, Button, Dropdown } from 'antd';
import Item from 'antd/es/list/Item';
import { FaKitMedical } from 'react-icons/fa6';
import { GiFirstAidKit } from 'react-icons/gi';
import { FaUser } from 'react-icons/fa';
import { TbGitBranchDeleted, TbReportMoney } from "react-icons/tb";
import { SiSemaphoreci } from "react-icons/si";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import NotLogin from '../components/notLogin';

function Pracstates() {
  var sno1 = 1
  var sno2 = 1

  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")

  const [kit, setkit] = useState([])
  const [practitioner, setpractitioner] = useState([])


  useEffect(() => {

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions = {
        method: 'GET',
        headers: myHeaders,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions)
        .then(response => response.json())
        .then(result => setkit(result))



      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders2,

        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner(result))




    } else if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {




      var myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");


      


      var urlencoded2 = new URLSearchParams();
      urlencoded2.append("id", id);
      urlencoded2.append("_id", id);

      var requestOptions2 = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded2,
        redirect: 'follow'
      };


      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setkit(result))
        .catch(error => console.log('error', error));


        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfo", requestOptions2)
        .then(response => response.json())
        .then(result => setpractitioner([result]) )
        .catch(error => console.log('error', error));


    }






  }, [])




  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {
    practitioner.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.name })
      options.push({ value: value.email })


    })


  }



  const handleInputChange = (e) => {
    e.preventDefault()






    const intttt = (searchTerm)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = practitioner.filter((item) =>

      item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



    );


    setpractitioner(filteredSuggestions)
    setsearchdone(true)

  };




  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
      .then(response => response.json())
      .then(result => setpractitioner(result))


    var myHeaders2 = new Headers();
    myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions2 = {
      method: 'GET',
      headers: myHeaders2,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
      .then(response => response.json())
      .then(result => setkit(result))


    setsearchdone(false)

  }




  const currentDate = dayjs().format('YYYY-MM');
  const { RangePicker } = DatePicker;
  const [firstYearMonth, setFirstYearMonth] = useState(currentDate);
  const [lastYearMonth, setLastYearMonth] = useState(currentDate);

  const changerange = (dates, dateStrings) => {
    if (dateStrings && dateStrings.length === 2) {
      setFirstYearMonth(dateStrings[0]);
      setLastYearMonth(dateStrings[1]);


    }
  };

  return (
    <div>

      {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
      <>

        <Navbar />
        <div className='deshboardmain'>
          <Sidemanu />
          <div className='adminkitmainbody'>
            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdOutlineQueryStats style={{ width: '50px', height: '50px' }} />Practitioner States</h1>
              {dooption()}
              {dooption()}

              {searchdone === false && <>
                <form onSubmit={handleInputChange}>
                  <AutoComplete
                    type="number"
                    style={{ width: 200 }}
                    options={options}
                    placeholder="Search by Email/Name"
                    filterOption={(inputValue, options) =>
                      options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      //  console.log(kitss) 
                    }
                    onChange={(inputValue) => setSearchTerm(inputValue)}
                  />
                  <button>Search</button>
                </form>

              </>}
              {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
              </>}
            </div>


            <div className='addbutton'> <RangePicker defaultValue={[dayjs(firstYearMonth), dayjs(lastYearMonth)]} onChange={changerange} picker="month" /></div>
            <div className='addbutton'>


              <div style={{ width: '200px', height: '50px', backgroundColor: "#f79191", display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> <h3>Kits less then 0</h3></div>
              <div style={{ width: '400px', height: '50px', backgroundColor: "#5159f7", display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> <h3>Kits more then 1 and less then 10</h3></div>
              <div style={{ width: '200px', height: '50px', backgroundColor: "#87f287", display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> <h3>Kits more then 10</h3></div>



            </div>

            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>S NO.</th>
                  <th>Practitioner Name</th>
                  <th>Practitioner Email</th>
                  <th>FS </th>

                  <th>DM </th>
                  <th>PT </th>


                </tr>
              </thead>
              <tbody>
                {practitioner.map((value, index) => {

                  var fs = 0
                  var mb = 0
                  var dm = 0
                  var pt = 0
                  var date

                  kit.map((value2, index) => {

                    value.assignedkits.map((value3) => {
                      if (value2._id === value3 && value2.Kittype === "dm") {


                        const rangeStartYearMonth = firstYearMonth.split('-');
                        const rangeEndYearMonth = lastYearMonth.split('-');


                        // Extract year and month from timestamp
                        const timestampYearMonth = value2.timestamp.slice(0, 7).split('-');

                        // Convert extracted parts to numbers
                        const rangeStartYear = parseInt(rangeStartYearMonth[0], 10);
                        const rangeStartMonth = parseInt(rangeStartYearMonth[1], 10);
                        const rangeEndYear = parseInt(rangeEndYearMonth[0], 10);
                        const rangeEndMonth = parseInt(rangeEndYearMonth[1], 10);
                        const timestampYear = parseInt(timestampYearMonth[0], 10);
                        const timestampMonth = parseInt(timestampYearMonth[1], 10);

                        // Check if the timestamp falls within the range
                        if (
                          timestampYear > rangeStartYear ||
                          (timestampYear === rangeStartYear && timestampMonth >= rangeStartMonth)
                        ) {
                          if (
                            timestampYear < rangeEndYear ||
                            (timestampYear === rangeEndYear && timestampMonth <= rangeEndMonth)
                          ) {
                            dm++;
                          }
                        }
                      }


                      if (value2._id === value3 && value2.Kittype === "pt") {


                        const rangeStartYearMonth = firstYearMonth.split('-');
                        const rangeEndYearMonth = lastYearMonth.split('-');

                        // Extract year and month from timestamp
                        const timestampYearMonth = value2.timestamp.slice(0, 7).split('-');

                        // Convert extracted parts to numbers
                        const rangeStartYear = parseInt(rangeStartYearMonth[0], 10);
                        const rangeStartMonth = parseInt(rangeStartYearMonth[1], 10);
                        const rangeEndYear = parseInt(rangeEndYearMonth[0], 10);
                        const rangeEndMonth = parseInt(rangeEndYearMonth[1], 10);
                        const timestampYear = parseInt(timestampYearMonth[0], 10);
                        const timestampMonth = parseInt(timestampYearMonth[1], 10);

                        // Check if the timestamp falls within the range
                        if (
                          timestampYear > rangeStartYear ||
                          (timestampYear === rangeStartYear && timestampMonth >= rangeStartMonth)
                        ) {
                          if (
                            timestampYear < rangeEndYear ||
                            (timestampYear === rangeEndYear && timestampMonth <= rangeEndMonth)
                          ) {
                            pt++;
                          }
                        }
                      }




                      if (value2._id === value3 && value2.Kittype === "mb") {


                        const rangeStartYearMonth = firstYearMonth.split('-');
                        const rangeEndYearMonth = lastYearMonth.split('-');

                        // Extract year and month from timestamp
                        const timestampYearMonth = value2.timestamp.slice(0, 7).split('-');

                        // Convert extracted parts to numbers
                        const rangeStartYear = parseInt(rangeStartYearMonth[0], 10);
                        const rangeStartMonth = parseInt(rangeStartYearMonth[1], 10);
                        const rangeEndYear = parseInt(rangeEndYearMonth[0], 10);
                        const rangeEndMonth = parseInt(rangeEndYearMonth[1], 10);
                        const timestampYear = parseInt(timestampYearMonth[0], 10);
                        const timestampMonth = parseInt(timestampYearMonth[1], 10);

                        // Check if the timestamp falls within the range
                        if (
                          timestampYear > rangeStartYear ||
                          (timestampYear === rangeStartYear && timestampMonth >= rangeStartMonth)
                        ) {
                          if (
                            timestampYear < rangeEndYear ||
                            (timestampYear === rangeEndYear && timestampMonth <= rangeEndMonth)
                          ) {
                            mb++;
                          }
                        }
                      }



                      if (value2._id === value3 && (value2.Kittype === "100" || value2.Kittype === "210")) {


                        const rangeStartYearMonth = firstYearMonth.split('-');
                        const rangeEndYearMonth = lastYearMonth.split('-');

                        // Extract year and month from timestamp
                        const timestampYearMonth = value2.timestamp.slice(0, 7).split('-');

                        // Convert extracted parts to numbers
                        const rangeStartYear = parseInt(rangeStartYearMonth[0], 10);
                        const rangeStartMonth = parseInt(rangeStartYearMonth[1], 10);
                        const rangeEndYear = parseInt(rangeEndYearMonth[0], 10);
                        const rangeEndMonth = parseInt(rangeEndYearMonth[1], 10);
                        const timestampYear = parseInt(timestampYearMonth[0], 10);
                        const timestampMonth = parseInt(timestampYearMonth[1], 10);

                        // Check if the timestamp falls within the range
                        if (
                          timestampYear > rangeStartYear ||
                          (timestampYear === rangeStartYear && timestampMonth >= rangeStartMonth)
                        ) {
                          if (
                            timestampYear < rangeEndYear ||
                            (timestampYear === rangeEndYear && timestampMonth <= rangeEndMonth)
                          ) {
                            fs++;
                          }
                        }
                      }
                    })


                  })


                  return (<>
                    <tr>
                      <td>{sno1++}</td>
                      <td>{value.name}</td>

                      <td>{value.email}</td>

                      <td style={{ backgroundColor: fs < 1 ? '#f79191' : (fs >= 1 && fs <= 9 ? '#5159f7' : '#87f287'), color: 'white' }}><h4>{fs}</h4></td>

                      <td style={{ backgroundColor: dm < 1 ? '#f79191' : (dm >= 1 && dm <= 9 ? '#5159f7' : '#87f287'), color: 'white' }}><h4>{dm}</h4></td>
                      <td style={{ backgroundColor: pt < 1 ? '#f79191' : (pt >= 1 && pt <= 9 ? '#5159f7' : '#87f287'), color: 'white' }}><h4>{pt}</h4></td>




                    </tr>
                  </>)
                })}
              </tbody>
            </table>

          </div>

        </div>
      </> : <NotLogin/>}






    </div>
  )
}

export default Pracstates