import React from 'react'
import { useNavigate } from 'react-router-dom';
const NotLogin = () => {
    const navigate = useNavigate();
  return (
    <div className="">
     
          <div className="" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <div className="" style={{ position: "relative", display: "inline-block" }}>
    <button
      style={{
        padding: "10px 63px",
        borderRadius: "20px",
        fontSize: "18px",
        background: "#6e4e9f",
        // background:"red",
        color: "white",
        border: "none",
        position: "absolute",
        top: "74.35%", // Center vertically
        left: "52.5%", // Center horizontally
        transform: "translate(-50%, -50%) rotate(-25deg)", // Center & Tilted
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      Login
    </button>

    {/* Image */}
    <img src={`/notlogin3.png`} alt="" width="30%" />
  </div>
</div>

      </div>
  )
}

export default NotLogin