
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/webinar.css"
import { useEffect } from 'react';
import { Button, Descriptions, Image, Input, message, Popconfirm } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineWeb } from 'react-icons/md';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImUpload2 } from 'react-icons/im';
import { imageDb } from "../firebase";
import { v4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AiFillDelete } from 'react-icons/ai';
import NotLogin from '../components/notLogin';
import { FaEdit } from 'react-icons/fa';


function Webinardetails() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const idd = Cookies.get("id")
    const token = Cookies.get("Token")
    const [question, setquestion] = useState([])
    const [field, setfield] = useState([])
    const navigate = useNavigate()

    const { id } = useParams();
    const [popopdetails, setpopopdetails] = useState(false)
    const [seequetion, setseequetion] = useState(true)
    const [popoprecording, setpopoprecording] = useState(false)
    const [ueser, setueser] = useState([])


    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            const intervalId = setInterval(() => {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("_id", id);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getalleventbyid", requestOptions)
                    .then(response => response.json())
                    .then(result => setquestion(result))
                    .catch(error => console.log('error', error));
            }, 5000); // 

            return () => clearInterval(intervalId); // Cleanup on component unmount
        }
    }, [token, id]);




    var sno1 = 1
    var sno2 = 1

    useEffect(() => {

        setfield(question.Fields)
    }, [question])


    const [popup, setpopup] = useState(false)
    const [popup2, setpopup2] = useState(false)

    const [addfield, setaddfield] = useState(false)
    const [coverphoto, setcoverphoto] = useState("")
    const [addfield2, setaddfield2] = useState(false)



    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            setCSVFile(prevFiles => [...prevFiles, ...Array.from(droppedFiles)]);
        } else {
            alert('Please drop valid files.');
        }
    };

    const [csvFile, setCSVFile] = useState([]);
    const handleFileUpload = async (event) => {




        const files = event.target.files;

        console.log(event.target.files)
        // console.log(csvFile)
        setCSVFile(prevFiles => [...prevFiles, ...Array.from(files)]);





    };


    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'] // removes formatting
        ],
    };

    const [title, settitle] = useState("")
    const [Schedule, setSchedule] = useState("")
    const [Access, setAccess] = useState("")
    const [Visibility, setVisibility] = useState("")
    const [URLL, setURLL] = useState("")
    const [About, setAbout] = useState("")
    const [Password, setPassword] = useState("")
    const [Category, setCategory] = useState("")
    const [Fields, setFields] = useState([
        { type: "text", title: "Name", require: true },
        { type: "email", title: "Email", require: true },

    ])


    const [Fieldstitle, setFieldstitle] = useState("")
    const [Fieldreq, setFieldreq] = useState(false)

    // const [Schedule, setSchedule] = useState("")
    // const [Schedule, setSchedule] = useState("")

    const firstform = (e) => {
        e.preventDefault()

        if (csvFile.length !== 0) {

            if (About.length !== 0) {




                setpopup2(true)
                setpopup(false)





            } else {
                message.error("Please Enter Descriptions ")
            }

        } else {
            message.error("Please Upload A Cover Photo")
        }

    }


    const [type, settype] = useState("")

    const submitsecontform = async (e) => {
        e.preventDefault()


        await setFields(prevFields => [
            ...prevFields,
            { type: type, title: Fieldstitle, require: Fieldreq, option: option }
        ]);
        setoption([{ value: "", placeholder: "Option 1" }, { value: "", placeholder: "Option 2" }])

        setFieldreq(false)
        console.log(Fields)

        setaddfield(false)
        setaddfield2(false)
    }


    const [option, setoption] = useState([{ value: "", placeholder: "Option 1" }, { value: "", placeholder: "Option 2" }])
    // const [option2, setoption2] = useState([])


    const editField = (index, newValue) => {
        // Create a new array with updated value at the specified index
        const updatedOptions = option.map((opt, i) =>
            i === index ? { ...opt, value: newValue } : opt
        );
        // Update the state with the new array
        setoption(updatedOptions);
    };





    const sentdatatobackend = async () => {

        const hide = message.loading("Action in progress", 0)
        var cvUrl
        if (coverphoto.length !== 0) {

            cvUrl = coverphoto;
        } else {
            const Carimageid = v4();
            const imgRef = ref(imageDb, `event/coverphoto/${Carimageid}.jpg`)
            await uploadBytes(imgRef, csvFile[0])
            cvUrl = await getDownloadURL(imgRef);
        }



        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("_id", question._id);
        urlencoded.append("title", title);
        urlencoded.append("Schedule", Schedule);
        urlencoded.append("Access", Access);
        urlencoded.append("Visibility", Visibility);
        urlencoded.append("URL", URLL);
        urlencoded.append("About", About);
        urlencoded.append("Password", Password);
        urlencoded.append("Category", Category);
        urlencoded.append("Fields", JSON.stringify(Fields));
        urlencoded.append("coverphoto", cvUrl);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/editevent", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setquestion(result)



                setpopup(false)
                setpopup2(false)

                setaddfield(false)
                setaddfield2(false)
                setoption([{ value: "", placeholder: "Option 1" }, { value: "", placeholder: "Option 2" }])
                settype("")


                settitle("")
                setSchedule("")
                setAccess("")
                setVisibility("")
                setURLL("")
                setAbout("")
                setPassword("")
                setCategory("")

                setFields([
                    { type: "text", title: "Name", require: true },
                    { type: "email", title: "Email", require: true },

                ])

                setFieldstitle("")
                setFieldreq(false)

            })
            .catch((error) => console.error(error));

        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 2000);

    }

    const [recording, setrecording] = useState("")

    const submitrecording = async (e) => {
        e.preventDefault()

        const hide = message.loading("Action in progress", 0)


        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("recording", recording);
        urlencoded.append("_id", question._id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addrentrecording", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {

                await setquestion(result)
                await setrecording("")
                await setpopoprecording(false)
            })
            .catch((error) => console.error(error));


        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 2000);
    }





    const downloadCSV = () => {



        let headers = [];
        let data = [];

        // Iterate over question.Fields and push each value.title to headers
        question.Fields.map((value) => {
            headers.push(value.title);
        });

        // Add "Join at" to the headers
        headers.push("Join at");

        // Iterate over each user and create a row of data for each
        question.user.map((user) => {
            let userRow = [];

            // Loop through headers and push the corresponding user data
            headers.forEach((header) => {
                if (header === "Join at") {
                    // Add the "Join at" data for the user
                    userRow.push(user.joinat || ""); // If `joinat` is undefined, push empty string
                } else {
                    // Add the corresponding field data for the user
                    userRow.push(user[header] || ""); // If the field is undefined, push empty string
                }
            });

            // Push the user row to the data array
            data.push(userRow);
        });

        const csvData = [
            headers, // Header row
            ...data    // Single data row
        ];

        // Convert array to CSV string
        const csvRows = csvData.map(row => row.join(",")).join("\n");

        // Create a Blob from the CSV string
        const blob = new Blob([csvRows], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Create a URL for the Blob and set it as the href attribute
        link.href = URL.createObjectURL(blob);

        // Set the download attribute with a filename
        link.download = `${question.title} Participants List.csv`;

        // Append the link to the document and trigger a click to start the download
        document.body.appendChild(link);
        link.click();

        // Remove the link after the download
        document.body.removeChild(link);

    }
    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ?
             <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdOutlineWeb style={{ width: '50px', height: '50px' }} />Academy</h1>
                        </div>







                        {popopdetails === true && <>
                            <div onClick={() => {
                                setpopopdetails(false)


                            }
                            } className='popupbg'></div>
                            <div className='popup'>


                                <div className='header' >
                                    <h2>User Details</h2>
                                </div>
                                <div className='kitdetailsmain' >


                                    {field.map((value, index) => {
                                        console.log(value)
                                        return (<>

                                            <div className='kitdetails'>
                                                <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  {value.title}</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}> {ueser[value.title]}  </h3>
                                            </div>

                                        </>)
                                    })}

                                    <div className='kitdetails'>
                                        <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}>  Action</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>        <a onClick={async (e) => {
                                            e.preventDefault()


                                            const hide = message.loading("Action in progress", 0)
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                            var urlencoded = new URLSearchParams();
                                            urlencoded.append("_id", question._id);
                                            urlencoded.append("email", ueser.Email);

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: 'follow'
                                            };

                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltuserfromwebinar", requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    setquestion(result)
                                                })
                                                .catch(error => console.log('error', error));


                                            await setTimeout(() => {
                                                hide(); // Call hide to stop the loading message
                                                message.success("Action completed successfully");
                                            }, 2000);
                                            await setpopopdetails(false)
                                        }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillDelete style={{ width: '20px', height: '20px' }} />Remove this Participant</a>

                                        </h3>
                                    </div>

                                </div>

                                <div className='bownpopupbutton'>
                                    <button onClick={() => {
                                        setpopopdetails(false)

                                    }
                                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>

                                </div>
                            </div>
                        </>}


                        {popoprecording === true && <>
                            <div onClick={() => {
                                setpopoprecording(false)


                            }
                            } className='popupbg'></div>
                            <div className='popup'>


                                <div className='header' >
                                    <h2>Please Provide link of Recording</h2>
                                </div>

                                <form onSubmit={submitrecording} style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
                                    <input value={recording} onChange={(e) => setrecording(e.target.value)} type='url' required placeholder='Enter link of Recording' />



                                    <div className='bownpopupbutton' style={{ width: '85%' }}>
                                        <button onClick={() => {
                                            setpopoprecording(false)

                                        }
                                        } style={{ border: '1px solid red', color: 'red', backgroundColor: '#fff' }} >cancel</button>





                                        <button className='button' >Submit</button>

                                    </div>
                                </form>
                            </div>
                        </>}




                        {popup === true && <>
                            <div onClick={() => {
                                setpopup(false)
                                setpopup2(false)

                            }
                            } className='popupbg'></div>
                            <div className='popup'>


                                <h2 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Add Event</h2>



                                <form className='eventform' onSubmit={firstform}>
                                    <div>
                                        <label>Title</label>
                                        <input required placeholder='Title' value={title} onChange={(e) => settitle(e.target.value)} />
                                    </div>


                                    <div>
                                        <label>Schedule</label>
                                        <input required type="datetime-local" id="meeting-time" name="meeting-time" value={Schedule} onChange={(e) => setSchedule(e.target.value)} />
                                    </div>


                                    <div>
                                        <label>Access</label>
                                        <select required value={Access} onChange={(e) => setAccess(e.target.value)}>
                                            <option disabled selected>Select</option>
                                            <option value={"Registration Required"}>Registration Required</option>
                                            <option value={"Tickets"}>Tickets</option>

                                        </select>
                                    </div>

                                    <div>
                                        <label>Event Password</label>
                                        <input placeholder='Enter event password for joining event' name='eentpassword' id='eentpassword' required type='text' value={Password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>


                                    <div>
                                        <label>Visibility</label>
                                        <select value={Visibility} required onChange={(e) => setVisibility(e.target.value)}>
                                            <option disabled selected>Select</option>
                                            <option value={"Unlisted"} >Unlisted</option>
                                            <option value={"Public"}>Public</option>

                                        </select>
                                    </div>

                                    <div>
                                        <label>URL</label>
                                        <Input required prefix="https://academy.yourgutmap.co.uk/" value={URLL} onChange={(e) => setURLL(e.target.value)} placeholder='Enter your Custom Url' className='input' type='' />
                                    </div>


                                    <div>
                                        <label>Category</label>
                                        <select value={Category} required onChange={(e) => setCategory(e.target.value)}>
                                            <option disabled selected>Select</option>
                                            <option value={"YOURGUTMAP"}>YOURGUTMAP</option>
                                            <option value={"BANT"}>BANT</option>
                                            <option value={"NNA"}>NNA</option>
                                            <option value={"RICTAT"}>RICTAT</option>
                                            <option value={"ANP"}>ANP</option>




                                        </select>

                                    </div>


                                    <div >
                                        <label>About</label>
                                        <div className='contantdiv'>


                                            <div className='divofevent'>
                                                <div>
                                                    <h5 style={{ margin: '0', padding: "0" }}>Cover Photo</h5 >
                                                    <p style={{ margin: '0', padding: "0" }}>
                                                        Recommended image size: 1200x630.<br /> Supported formats: JPEG, PNG
                                                    </p>
                                                </div>

                                                {csvFile.length === 0 ? (<>
                                                    <div className="file-drop-zone"

                                                        style={{ display: "flow", width: "65%" }}
                                                        onDrop={handleFileDrop}
                                                        onDragOver={(e) => e.preventDefault()}

                                                    >

                                                        <label className="custom-file-upload" style={{ backgroundColor: 'white', height: "fit-content" }}>

                                                            <div className='clickablediv' style={{ height: "fit-content" }}>


                                                                <ImUpload2 style={{ width: '100px', height: '100px' }} />
                                                                <h4>Click or drag file to this area to upload</h4>


                                                            </div>
                                                            <input
                                                                multiple="off"
                                                                className='inputbuttontouploadfile'
                                                                type="file"
                                                                accept=".png, .jpg, .jpeg, .gif"  // Allow image file types
                                                                onChange={handleFileUpload}
                                                            />


                                                        </label>

                                                    </div>

                                                </>) : (<>
                                                    <div className='imgwaladiv'>
                                                        <div className='imgwaladiv2' onClick={() => {
                                                            setCSVFile([])
                                                            setcoverphoto("")
                                                        }} style={{ justifyContent: 'center' }}><br />Remove</div>

                                                        {coverphoto.length === 0 ? (<>

                                                            <img width={"100%"} src={URL.createObjectURL(csvFile[0])} alt='' />

                                                        </>) : (<>
                                                            <img width={"100%"} src={coverphoto} alt='' />
                                                        </>)}
                                                    </div>
                                                </>)}
                                            </div>




                                            <div  >
                                                <ReactQuill
                                                    require="true"
                                                    value={About}
                                                    onChange={setAbout}
                                                    modules={modules}
                                                    placeholder="Add a description"
                                                // theme="snow" // snow is the default theme
                                                />
                                            </div>
                                        </div>
                                    </div>



                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between !important", gap: '20px', width: '100%' }}>
                                        <button onClick={() => {
                                            setpopup(false)
                                            setpopup2(false)

                                        }
                                        } style={{ border: '1px solid red', color: 'black', backgroundColor: '#fff' }} >cancel</button>





                                        <button style={{ backgroundColor: '#4180b7' }}  >Next</button>


                                    </div>
                                </form>

                            </div>
                        </>}


                        {popup2 === true && <>
                            <div onClick={() => {
                                setpopup(false)
                                setpopup2(false)

                            }
                            } className='popupbg'></div>
                            <div className='popup'>


                                <h2 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Custom Fields</h2>



                                <form className='eventform' onSubmit={submitsecontform}>
                                    <div>
                                        <p>
                                            Tailor the data collected during registration to your needs. Seamlessly integrate attendee information with your CRM or Email Marketing platform.

                                        </p>

                                        {addfield === true ? (<>



                                            {addfield2 === false ? (<>
                                                <div className='fleidbox'>
                                                    <h3>What kind of field would you like to add?</h3>
                                                    <div >
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("text")
                                                        }} className='hovarrrr'>Text</div>
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("phone")
                                                        }} className='hovarrrr'>Phone</div>
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("url")
                                                        }} className='hovarrrr'>URL</div>

                                                    </div>
                                                    <div >
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("date")
                                                        }} className='hovarrrr'>Date</div>
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("number")
                                                        }} className='hovarrrr'>number</div>
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("checkbox")
                                                        }} className='hovarrrr'> Checkbox</div>

                                                    </div>
                                                    <div >
                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("select")
                                                        }} className='hovarrrr'>Multiple choice</div>

                                                        <div onClick={() => {
                                                            setaddfield2(!addfield2)
                                                            settype("Country")
                                                        }} className='hovarrrr'>Multiple choice(country)</div>
                                                        {/* <div>number</div>
                                                <div> Checkbox</div> */}

                                                    </div>


                                                </div>

                                            </>) : (<>



                                                <div className='fleidbox'>
                                                    <h3>{type}</h3>
                                                    <div>

                                                        <input required placeholder='What Should be the Title' onChange={(e) => setFieldstitle(e.target.value)} />
                                                    </div>


                                                    {type === "select" && <>


                                                        <h5>Options</h5>

                                                        {option.map((option, i) => (
                                                            <div key={i}>
                                                                <input required placeholder={`Option ${i + 1}`} value={option.value}
                                                                    onChange={(e) => editField(i, e.target.value)} />
                                                            </div>
                                                        ))}


                                                        <div>


                                                            <button onClick={() => {
                                                                setoption(prevFields => [
                                                                    ...prevFields,
                                                                    { value: "", placeholder: `Option ${option.length + 1}` }
                                                                ]);
                                                            }} type='button'>Add option</button>
                                                        </div>

                                                    </>}


                                                    <div style={{ height: '40px', alignItems: 'center' }}>

                                                        <input onChange={(e) => setFieldreq(e.target.checked)} type='checkbox' style={{ width: '30px', height: '40px' }} />
                                                        <label>Required Field</label>
                                                    </div>

                                                    <button className='button2' style={{ borderRadius: '5px', width: '100%' }}>Add Custom Fields</button>


                                                    <button onClick={() => {
                                                        setaddfield(false)
                                                        setaddfield2(false)
                                                    }} className='dltbtn' style={{ backgroundColor: '#fff', border: "1px solid red", color: "black", borderRadius: '5px', width: '100%' }}>Cenel</button>

                                                </div>

                                            </>)}



                                        </>) : (<>
                                            <div className='fleidbox'>
                                                <h3>Included</h3>
                                                <div style={{ display: "flex", flexWrap: 'wrap' }}>
                                                    {Fields.map((value, index) => {


                                                        return (<>

                                                            <Popconfirm
                                                                title="Delete the Field"
                                                                description="Are you sure to delete this Field"
                                                                onConfirm={() => {
                                                                    setFields(prevFields => prevFields.filter((_, i) => i !== index));
                                                                }}

                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <div style={{ minWidth: '100px', cursor: 'pointer', maxWidth: 'fit-content' }}>{value.title}</div>
                                                            </Popconfirm>
                                                        </>)
                                                    })}

                                                </div>

                                                <button
                                                    type="button"
                                                    onClick={() => setaddfield(true)}
                                                    className='button2'
                                                    style={{ borderRadius: '5px', width: '100%' }}
                                                >
                                                    Add Custom Fields
                                                </button>

                                            </div>


                                        </>)}






                                    </div>







                                </form>


                                <div className='bownpopupbutton'>
                                    <button onClick={() => {
                                        setpopup(false)
                                        setpopup2(false)

                                    }
                                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>





                                    <button onClick={sentdatatobackend} style={{ backgroundColor: '#4180b7' }} >Edit</button>


                                </div>




                            </div>
                        </>}





                        {(question && question.length !== 0) ? (<>

                            <div className='questionbox position'>

                                <button className='button position2'

                                    onClick={() => {
                                        setpopup(true)
                                        settitle(question.title)
                                        setSchedule(question.Schedule)
                                        setAccess(question.Access)
                                        setPassword(question.Password)
                                        setVisibility(question.Visibility)
                                        setURLL(question.URL)
                                        setCategory(question.Category)
                                        setCSVFile(question.coverphoto)
                                        setcoverphoto(question.coverphoto)
                                        setAbout(question.About)
                                        setFields(question.Fields)

                                    }}

                                >Edit Event</button>

                                <h3 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Upcoming Events</h3>

                                <div className='question'>

                                    <div className='headerforimg'>
                                        <div>
                                            <h4 style={{ display: 'flex', margin: "0" }}> {question.title}</h4>
                                            <h5 style={{ display: 'flex', margin: "0" }}> Date: {question.Schedule}</h5>

                                            <h5 style={{ display: 'flex', margin: "0" }}> url: <a href={`https://academy.yourgutmap.co.uk/${question.URL}`} >https://academy.yourgutmap.co.uk/{question.URL}</a> </h5>

                                        </div>
                                        <div className='coverphoto'>
                                            <Image src={question.coverphoto} width={"100%"} alt='' />
                                        </div>
                                    </div>
                                    {/* <h5 style={{color: '#6E4E9F', display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Descriptions</h5> */}

                                    <p style={{ display: 'flex', textAlign: 'left' }}>

                                        <span dangerouslySetInnerHTML={{ __html: question.About }} />
                                    </p>


                                </div>

                                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>

                                    <div style={{ width: 'fit-content', height: '35px', display: 'flex' }}>


                                        {!question.status ? (<>
                                            <button className='button' onClick={async () => {


                                                navigate("/dashboard/webinar/meeting/" + question.URL)


                                            }}>Join MEETING</button>

                                            <button className='button' style={{ backgroundColor: 'red', marginLeft: '10px' }} onClick={async () => {


                                                setpopoprecording(true)


                                            }}>End This Event</button>

                                        </>) : (<> Event is Ended ({question.recording}) <FaEdit onClick={()=>setpopoprecording(true)} style={{width:'25px',height:'25px',color:'#6E4E9F',cursor:'pointer'}}/></>)}


                                    </div>


                                    <Popconfirm
                                        title="Delete the task"
                                        description="Are you sure to delete this?"
                                        onConfirm={async () => {

                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                            var urlencoded = new URLSearchParams();
                                            urlencoded.append("_id", question._id);

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: 'follow'
                                            };


                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltevent", requestOptions)
                                                .then(response => response.json())
                                                .then(async result => {
                                                    await navigate("/dashboard/webinar")

                                                })
                                                .catch(error => console.log('error', error));







                                        }}

                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button danger >Delete This Event</Button>
                                    </Popconfirm>
                                </div>




                                <div style={{ display: 'flex' }}>

                                    {seequetion ? (<>
                                        {question.status ? (<>
                                            <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Registered Participants</h4>
                                            <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> Questions</h4>
                                        </>) : (<>

                                            <h4 style={{ display: 'flex', margin: "0" }}> Registered Participants  : {question.user.length}</h4>

                                        </>)}
                                    </>) : (<>


                                        <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> Registered Participants</h4>
                                        <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Questions</h4>

                                    </>)}

                                </div>

                                {seequetion ? (<>
                                    {question.user !== undefined ? (<>

                                        <div className='header'><h1></h1><button className='button' onClick={downloadCSV}>Download CSV File</button></div>
                                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                            <thead className='tablephead'>
                                                <tr>
                                                    <th >S No.</th>
                                                    <th >Name</th>
                                                    <th >Email</th>
                                                    <th >Join at</th>
                                                    <th >Action</th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {question.user.map((value) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{sno1++}</td>
                                                            <td>{value.Name}</td>
                                                            <td>{value.Email}</td>
                                                            <td>{value.joinat}</td>
                                                            <td><button className='button'
                                                                onClick={() => {
                                                                    setpopopdetails(true)
                                                                    setueser(value)

                                                                }}
                                                            >Detail</button></td>
                                                        </tr>
                                                    </>)
                                                })}
                                            </tbody>
                                        </table>

                                    </>) : (<></>)}
                                </>) : (<>
                                    {question.questions !== undefined ? (<>

                                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                            <thead className='tablephead'>
                                                <tr>
                                                    <th >S No.</th>
                                                    <th >Time</th>
                                                    <th >Name and Email</th>
                                                    <th >Question</th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {question.questions.map((value) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{sno2++}</td>
                                                            <td>{value.time}</td>
                                                            <td>{value.name}<br />{value.email}</td>
                                                            <td>{value.quetion}</td>

                                                        </tr>
                                                    </>)
                                                })}
                                            </tbody>
                                        </table>

                                    </>) : (<></>)}
                                </>)}
                            </div>
                        </>) : (<>loading...</>)}
                    </div>
                </div>
            </> : <NotLogin/>}
        </div>
    )
}

export default Webinardetails
