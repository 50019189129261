import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { MdAssignmentReturn, MdOutlineAnalytics } from 'react-icons/md';
import { message } from 'antd';
import NotLogin from '../components/notLogin';
import axios from 'axios';

function KitAnalysis() {

    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const loginid = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")
    const apiurlforbackend = process.env.REACT_APP_APIURL

    const [kit, setkit] = useState([])

    const [page, setpage] = useState(1)

    const [totalPages, settotalPages] = useState(1)

    const [practitioner, setpractitioner] = useState([])


    const setkitbyres = async (response) => {

        setkit(response.data.data);

        setpractitioner(response.data.practitioner);

        setpage(response.data["current page"])

        settotalPages(response.data["total pages"])



    }




    const getkits = async (page) => {




        let data = JSON.stringify({
            "pagenumber": page,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getkitthatare6montholdsbytype',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitbyres(response)


            })
            .catch((error) => {
                console.log(error);
            });


    }


    useEffect(() => {

        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") { getkits(page) }
    }, [])



    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
        const startPage = Math.max(1, page - maxPageToShow);
        const endPage = Math.min(totalPages, page + maxPageToShow);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={page === i ? 'activeeee' : ''}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };



    const handlePageClick = async (pageNumber) => {
        await setkit([])
        await window.scrollTo(0, 0);
        await getkits(pageNumber)

        await renderPageNumbers()
    };



    var sno2 = 1 + (15 * (page - 1))

    return (
        <>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ?
             <>

                <Navbar />
                <div className='deshboardmain'>

                    <Sidemanu />

                    <div className='adminkitmainbody'>

                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><MdOutlineAnalytics style={{ width: '30px', height: '30px' }} />Kit Analysis</h1>

                        </div>








                        {kit.length !== 0 && <>
                            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                <thead className='tablephead'>
                                    <tr>
                                        {/* <th><input type='checkbox' /></th> */}
                                        <th>S NO.</th>
                                        <th>Kit ID</th>
                                        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>     <th>Assigned to</th></>}
                                        <th>Test Type</th>
                                        <th>Patient Name</th>
                                        <th>Analysis on</th>

                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>


                                    {kit.map((item, index) => (<>

                                        <tr>
                                            <td>{sno2++}</td>

                                            <td>{item.kitid}</td>
                                            {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>
                                                {item.assignedto ? (
                                                    <td style={{ display: "flex", flexDirection: 'column', textAlign: 'left', }}>
                                                        {practitioner.map((item2, index) => (<>

                                                            {item2._id === item.assignedto && <>

                                                                <td style={{ border: "none", width: '50%' }}>{item2.name} </td>
                                                                <td style={{ border: "none", width: '50%' }}>{item2.email}</td>
                                                            </>}


                                                        </>))}
                                                    </td>
                                                ) : (
                                                    <td className='hovar' style={{ color: '#4180B7', display: "flex", alignItems: 'center', justifyContent: "center" }} onClick={() => {


                                                    }} >< MdAssignmentReturn style={{ width: '30px', height: '30px', color: '#4180B7' }} /><h5> Not Assigned yet</h5></td>

                                                )}


                                            </>}


                                            <td>{item.Kittype}</td>

                                            <td>{item.patientName}</td>

                                            <td>{item.resultDate}</td>




                                            <td>
                                                {item.ack ? (<> <button className='button' style={{ backgroundColor: "#fff", border: '1px solid #6E4E9F', color: "#6E4E9F" }}
                                                    onClick={() => {
                                                        // setpopup2(true)
                                                        // setkitdetails(item)
                                                    }}

                                                >Already Sent </button>

                                                </>) : (<>
                                                    <button className='button'
                                                        onClick={async () => {

                                                            const hide = message.loading("Action in progress", 0)

                                                            let data = JSON.stringify({
                                                                "pagenumber": page,
                                                                "kitid": item.kitid,
                                                                "kit_id": item._id,
                                                                "pracid": item.assignedto,
                                                                "patientName": item.patientName,
                                                            });

                                                            let config = {
                                                                method: 'post',
                                                                maxBodyLength: Infinity,
                                                                url: apiurlforbackend + '/getkitthatare6montholdsbytypesendemail',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                data: data
                                                            };

                                                            await axios.request(config)
                                                                .then((response) => {
                                                                    setkitbyres(response)


                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                });


                                                            setTimeout(() => {
                                                                hide(); // Call hide to stop the loading message
                                                                message.success("Action completed successfully");
                                                            },);


                                                        }}

                                                    >Send Email </button>

                                                </>)}
                                            </td>


                                        </tr>

                                    </>))}






                                </tbody>
                            </table>




                        </>}

                        {kit.length === 0 && <>
                            {totalPages === 0 ? (<>
                                <img alt='' src='/empty2.gif' width={"35%"} />
                            </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
                        </>}
                        <div className="pagination">
                            {page > 1 && (
                                <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
                            )}
                            {renderPageNumbers()}
                            {page < totalPages && (
                                <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
                            )}
                        </div>


                    </div>

                </div>




            </> : <NotLogin/>}

        </>
    )
}

export default KitAnalysis
