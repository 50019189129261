import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/Practitioner.css"

import { AiOutlineClose } from "react-icons/ai"

import Cookies from 'js-cookie';
import { AutoComplete, Image } from 'antd';

import { FaUserDoctor } from "react-icons/fa6";
import axios from "axios"
import NotLogin from './notLogin';

function Practitionerpage(prams) {


    const token = Cookies.get("Token")
    const type = `${prams.kittype}`
    const [page, setpage] = useState(1)
    const [page2, setpage2] = useState(1)
    const apiurlforbackend = process.env.REACT_APP_APIURL
    const [totalPages, settotalPages] = useState(1)
    const [totalPages2, settotalPages2] = useState(1)

    const [kitids, setkitids] = useState([])
    const [popupdetails, setpopupdetails] = useState(false)

    const [userinfo, setuserinfo] = useState([])


    const [practitioner, setpractitioner] = useState([])
    const [practitioner2, setpractitioner2] = useState([])

    var sno1 = 1
    var sno2 = 1 + (15 * (page - 1))

    var options = kitids;











    const setpracbyres = async (response) => {

        setpractitioner(response.data.practitioners);
        setpractitioner2(response.data.practitioners);
        setpage(response.data["current page"])
        setpage2(response.data["current page"])
        settotalPages(response.data["total pages"])
        settotalPages2(response.data["total pages"])


    }




    const getprac = async (page) => {




        let data = JSON.stringify({
            "pagenumber": page,
            "type": type,

        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getpracbytype',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setpracbyres(response)


            })
            .catch((error) => {
                console.log(error);
            });


    }



    const getkitsids = async () => {




        let data = JSON.stringify({

            "type": type,

        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getpracnameandemail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitids(response.data.pracname);

            })
            .catch((error) => {
                console.log(error);
            });


    }


    useEffect(() => {

        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            getprac(page)
            getkitsids()
        }
    }, [])


    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
        const startPage = Math.max(1, page - maxPageToShow);
        const endPage = Math.min(totalPages, page + maxPageToShow);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={page === i ? 'activeeee' : ''}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };



    const handlePageClick = async (pageNumber) => {
        await setpractitioner([])
        // await window.scrollTo(0, 0);
        await getprac(pageNumber)

        await renderPageNumbers()
    };






    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = async (e) => {
        e.preventDefault()

        setpractitioner([])


        let data = JSON.stringify({

            "type": type,
            "searchString": searchTerm,

        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/searchpracbytype',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then(async (response) => {

                await setpractitioner(response.data.practitioners)
                await setpage(1)
                await settotalPages(1)
                await renderPageNumbers()

            })
            .catch((error) => {
                console.log(error);
            });

        setsearchdone(true)

    };




    const [searchdone, setsearchdone] = useState(false)

    const clearsearch = async () => {



        await setpractitioner(practitioner2)
        await setpage(page2)
        await settotalPages(totalPages2)
        await renderPageNumbers()

        setsearchdone(false)

    }


    return (<>
        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? 
         <>
            <Navbar />
            <div className='deshboardmain'>
                <Sidemanu />
                <div className='Practitionermainbody'>


                    <div className='header'>
                        <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaUserDoctor style={{ width: '35px', height: '35px' }} />{type} Practitioners</h1>



                        {searchdone === false && <>
                            <form onSubmit={handleInputChange}>
                                <AutoComplete

                                    style={{ width: 200 }}

                                    options={options}
                                    placeholder="Search by Email/Name"
                                    filterOption={(inputValue, options) =>
                                        options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

                                    }
                                    onChange={(inputValue) => setSearchTerm(inputValue)}
                                />
                                <button>Search</button>
                            </form>

                        </>}
                        {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                        </>}
                    </div>




                    {popupdetails === true && <>
                        <div onClick={() => {
                            setpopupdetails(false)


                        }
                        } className='popupbg'></div>
                        <div className='popup' style={{ height: '600px !important' }}>



                            <div className='profilemainbody' style={{ width: '96%', backgroundColor: '#EDEDED', minHeight: 'fit-content' }}>






                                <div className='uperprofile'>

                                    <div className='profilepicture' >
                                        <Image alt='avatar' src={userinfo.practitioner.profilepic} width={"300px"} />

                                    </div>
                                    <div className='profileabot'>

                                        {userinfo.practitioner.name && <>
                                            <div ><h3 style={{ width: '200px' }}>Name : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.name} </p></div>
                                        </>}

                                        {userinfo.practitioner.email && <>
                                            <div ><h3 style={{ width: '200px' }}>Email : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.email} </p></div>
                                        </>}

                                        {userinfo.practitioner.phone && <>
                                            <div ><h3 style={{ width: '200px' }}>phone : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.phone} </p></div>
                                        </>}

                                        {userinfo.practitioner.address && <>
                                            <div ><h3 style={{ width: '200px' }}>address : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.address} </p></div>
                                        </>}

                                        {userinfo.practitioner.billingpostcode && <>
                                            <div ><h3 style={{ width: '200px' }}>billing post code : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.billingpostcode} </p></div>
                                        </>}

                                        {userinfo.practitioner.timestamp && <>
                                            <div ><h3 style={{ width: '200px' }}>Registered On :   </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.timestamp} </p></div>
                                        </>}

                                        {userinfo.practitioner.about && <>
                                            <div style={{ width: '100%', margin: '0px 0' }}>
                                                <h2>About Me</h2>
                                                <p style={{ width: '100%', margin: '0' }}>{userinfo.practitioner.about} </p>
                                            </div>

                                        </>}





                                    </div>

                                </div>


                                <div className='profileinfo'>

                                    <div className='infoboxinside' style={{ width: '150px' }}>
                                        <h2>{userinfo.kits.length}</h2>

                                        <p style={{ margin: '0' }}>TOTAL {type} KITS</p>
                                    </div>

                                    <div className='infoboxinside'>

                                        <h2 style={{ margin: '0' }}>{userinfo["analysiscompleted"]}</h2>
                                        <p style={{ margin: '0' }}>ANALYSIS COMPLETED</p>
                                    </div>


                                    <div className='infoboxinside'>

                                        <h2>{userinfo["analysispending"]}</h2>
                                        <p style={{ margin: '0' }}>ANALYSIS PENDING
                                        </p>
                                    </div>





                                </div>



                                <div className='imp' style={{ width: "90%" }}>
                                    <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ width: '100%' }}>
                                        <thead className='tablephead' >
                                            <tr>
                                                <th>S No.</th>
                                                <th>KIT ID</th>
                                                <th>Received Acknowledgment</th>
                                                <th>Payment Acknowledgment</th>
                                                <th>Result Acknowledgment</th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userinfo.kits.map((value, index) => {
                                                let Received
                                                let Payment
                                                let Result


                                                if (value.resultuploaded && value.resultack) {
                                                    Result = "Results are ready and sent"
                                                } else if (value.resultuploaded && !value.resultack) {
                                                    Result = "Results are ready and need to sent"
                                                } else {
                                                    Result = "Results are not ready"
                                                }


                                                if (value.Kitprice === "fdgbhjbdgfhjdfgsbf" && value.ackpayment) {
                                                    Payment = "Payment Done and Acknowledgment sent"
                                                } else if (value.Kitprice === "fdgbhjbdgfhjdfgsbf" && !value.ackpayment) {
                                                    Payment = "Payment Done and need to sent Acknowledgment"
                                                } else {
                                                    Payment = "Payment Pending"
                                                }

                                                if ((value.receiveddate || value.status === "KIT Received in Lab, Awaiting Results" || value.status === "Results Published") && value.ack) {
                                                    Received = "Received in lab and Acknowledgment sent"
                                                } else if ((value.receiveddate || value.status === "KIT Received in Lab, Awaiting Results" || value.status === "Results Published") && !value.ack) {
                                                    Received = "Received in lab and need to sent Acknowledgment"
                                                } else {
                                                    Received = "Haven't Received in lab yet"
                                                }

                                                return (<>
                                                    <tr>
                                                        <td>{sno1++}</td>
                                                        <td><h4>{value.kitid}</h4></td>
                                                        <td>{Received}</td>
                                                        <td>{Payment}</td>
                                                        <td>{Result}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </div>
                    </>}







                    {practitioner.length !== 0 && <>
                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                            <thead className='tablephead'>
                                <tr>

                                    <th>S NO.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Assigned Kits</th>


                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>







                                {practitioner.map((item, index) => (<>



                                    <tr>


                                        <td>{sno2++}</td>
                                        <td>{item.practitioner.name} </td>
                                        <td>{item.practitioner.email}</td>
                                        <td>{item.kits.length}</td>




                                        <td>
                                            <button className='button' onClick={async () => {

                                                await setuserinfo(item)

                                                await setpopupdetails(true)


                                            }}>Detail</button>
                                        </td>
                                    </tr>



                                </>))}







                            </tbody>
                        </table>


                    </>}




                    {practitioner.length === 0 && <>
                        {totalPages === 0 ? (<>
                            <img alt='' src='/empty2.gif' width={"35%"} />
                        </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
                    </>}

                    <div className="pagination">
                        {page > 1 && (
                            <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
                        )}
                        {renderPageNumbers()}
                        {page < totalPages && (
                            <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
                        )}
                    </div>

                </div>


            </div>
        </> :<NotLogin/> } </>)
}

export default Practitionerpage