import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { AutoComplete, Button, Checkbox, Drawer, Dropdown, message, Popconfirm } from 'antd';
import { AiFillDelete, AiFillEdit, AiOutlineClose, AiOutlineInteraction } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaDownload, FaFilter, FaPlaceOfWorship, FaRegAddressCard, FaRegClock, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown, FaUserGroup } from 'react-icons/fa6';
import { SiGoogleadsense } from 'react-icons/si';
import "../css/leads.css"
import { IoIosArrowDropdown } from 'react-icons/io';
import { IoCall } from 'react-icons/io5';
import { ImUpload2 } from 'react-icons/im';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { TiPlusOutline } from 'react-icons/ti';

function Leadscoverted() {

  var sno2 = 1
  var sno2a = 1
  var sno2b = 1
  var sno2c = 1

  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const apiurlforbackend = process.env.REACT_APP_APIURL

  const fileInputRef = useRef(null);
  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [popup3, setpopup3] = useState(false)
  const [action, setaction] = useState("")
  const [practitioner, setpractitioner] = useState([])
  const [leads, setleads] = useState([])
  const [leads2, setleads2] = useState([])
  const [page, setpage] = useState(1)
  const [page2, setpage2] = useState(1)

  const [totalPages, settotalPages] = useState(1)
  const [totalPages2, settotalPages2] = useState(1)
  const [filterregister, setfilterregister] = useState("All")
  const [filterlimit, setfilterlimit] = useState(15)
  const [total, settotal] = useState(0)
  const [total2, settotal2] = useState(0)

  const [filtersource, setfiltersource] = useState([])
  const [filtersourcetoshow, setfiltersourcetoshow] = useState([])
  var sno1 = 1 + (filterlimit * (page - 1))





  const setleadsfromresponce = async (result) => {

    setleads(result["data"])
    setleads2(result["data"])
    setpage(result["current page"])
    setpage2(result["current page"])

    settotalPages(result["total pages"])
    settotalPages2(result["total pages"])
    settotal(result["total leads"])
    settotal2(result["total leads"])

    // setfilterregister(result["register"])
    // setfiltersource(result["source"])
  }


  const sersrc = async () => {
    try {
      const response = await axios.get(apiurlforbackend + "/getsrclist");
      if (response.data?.sources) {
        setfiltersourcetoshow(response.data.sources);
        setfiltersource(response.data.sources);
        return response.data.sources; // Return the sources so they can be used in `getdata()`
      }
    } catch (error) {
      console.log("Error fetching source list:", error);
    }
    return []; // Return an empty array in case of error
  };

  const getleads = async (pagenumber, sourceList) => {
    try {
      const data = JSON.stringify({
        pagenumber,
        source: sourceList || filtersource, // Ensure source list is set correctly
        register: filterregister,
        limit: filterlimit,
        type: "converted",
      });

      const response = await axios.post(apiurlforbackend + "/getleadsbypage", data, {
        headers: { "Content-Type": "application/json" },
      });

      setleadsfromresponce(response.data);
    } catch (error) {
      console.log("Error fetching leads:", error);
    }
  };

  const getdata = async () => {
    const sourceList = await sersrc(); // First, fetch the source list
    await getleads(page, sourceList); // Then fetch leads using the retrieved source list
  };

  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      getdata();
    }
  }, [token]);


  const [leadsinfo, setleadsinfo] = useState([])





  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",

    address: "",
    message: ""
  });

  const [formDataemail, setFormDataemail] = useState({
    subject: "",
    content: "",
    option: "Send YGM Brand Email",

  });

  const [formDatacallandmeet, setFormDatacallandmeet] = useState({
    subject: "",


  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleChangeemail = (e) => {
    const { name, value } = e.target;
    setFormDataemail((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const handleChangecallandmeet = (e) => {
    const { name, value } = e.target;
    setFormDatacallandmeet((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const senddata = async (e) => {

    e.preventDefault()
    const hide = message.loading("Action in progress", 0)


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("Name", formData.name);
    urlencoded.append("Email", formData.email);
    urlencoded.append("Address", formData.address);
    urlencoded.append("Message", formData.message);
    urlencoded.append("Phone number", formData.phone);
    urlencoded.append("srccc", "portal");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/addleadbyweb", requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result === "user found") {

          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.error("This Lead is already exists in our Database")
          }, 2000);

        } else {
          // setleads(result)
          getleads(page)
          setFormData({
            name: "",
            email: "",
            phone: "",

            address: "",
            message: ""
          });
          setpopup2(false)


          setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
          }, 2000);
        }

      })
      .catch((error) => console.error(error));




  }

  const sendemail = async (e) => {

    e.preventDefault()
    const hide = message.loading("Action in progress", 0)


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("subject", formDataemail.subject);
    urlencoded.append("content", formDataemail.content);
    urlencoded.append("option", formDataemail.option);
    urlencoded.append("_id", leadsinfo._id);


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sendleademail", requestOptions)
      .then((response) => response.json())
      .then((result) => {


        // setleads(result)
        getleads(page)
        setFormDataemail({
          subject: "",
          content: "",
          option: "Send YGM Brand Email",
        });
        setpopup3(false)


        setTimeout(() => {
          hide(); // Call hide to stop the loading message
          message.success("Action completed successfully");
        }, 2000);


      })
      .catch((error) => console.error(error));




  }

  const sendcallandmeet = async (e) => {

    e.preventDefault()
    const hide = message.loading("Action in progress", 0)


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("subject", formDatacallandmeet.subject);
    urlencoded.append("action", action);
    urlencoded.append("_id", leadsinfo._id);


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/sendleadcallandmeet", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {


        // setleads(result)
        setFormDatacallandmeet({
          subject: "",

        });
        await getleads(page)

        setpopup3(false)

        setTimeout(() => {
          hide(); // Call hide to stop the loading message
          message.success("Action completed successfully");
        }, 2000);


      })
      .catch((error) => console.error(error));




  }

  const items1 = [
    {
      key: '1',
      label: (
        <a onClick={async (e) => {
          await setpopup2(false)
          await setpopup(false)
          await setaction("email")
          await setpopup3(true)
        }}> Send Email </a>
      ),

      icon: <MdEmail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },
    {
      key: '2',
      label: (
        <a onClick={async (e) => {
          await setpopup2(false)
          await setpopup(false)
          await setaction("Meeting")
          await setpopup3(true)
        }}> Meeting Arranged   </a>
      ),

      icon: <FaUserGroup style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },

    {
      key: '3',
      label: (
        <a onClick={async (e) => {
          await setpopup2(false)
          await setpopup(false)
          await setaction("Call")
          await setpopup3(true)
        }}> Had Call  </a>
      ),

      icon: <IoCall style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

    },


  ];


  const [seequetion, setseequetion] = useState(true)







  const [searchTerm, setSearchTerm] = useState('');
  var options = [];









  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {


    setleads(leads2)

    setpage(page2)

    settotalPages(totalPages2)

    settotal(total2)
    setsearchdone(false)

  }

  const [popup4, setpopup4] = useState(false)
  const [csvFile, setCSVFile] = useState(null);
  const [csvData, setCsvData] = useState([]);




  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // console.log(event.target.files)
    // console.log(csvFile)
    setCSVFile(file)

    await Papa.parse(file, {
      complete: (result) => {

        setCsvData(result.data);
        // setCsvData(result.data[0])

      },
      header: true,
      skipEmptyLines: true
    });



  };


  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      setCSVFile(droppedFile);
      Papa.parse(droppedFile, {

        complete: (result) => {

          setCsvData(result.data);
          // setCsvData(result.data[0])

        },
        header: true,
        skipEmptyLines: true
      });


    } else {
      alert('Please drop a valid CSV file.');
    }
  };





  const datauploaded = async () => {

    if (csvFile) {
      const hide = message.loading("Action in progress", 0)




      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("file", JSON.stringify(csvData));


      urlencoded.append("filename", csvFile.name);


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      await fetch("http://localhost:1337/testtoaddkit", requestOptions)
        .then((response) => response.json())
        .then((result) => {


          // setleads(result)

          // getleads(page)
        })

      // await getleads()
      // await setpopup4(false)
      // await setCSVFile(null)
      await setTimeout(() => {
        hide(); // Call hide to stop the loading message
        message.success("Action completed successfully");
      }, 2000);
    }
    else { message.error("please upload a file first") }


  }




  const handleClearInput = () => {
    // setFiles([]); // Clear the files state
    fileInputRef.current.value = ''; // Clear the file input value
    setCSVFile(null)
  };




  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };







  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, page - maxPageToShow);
    const endPage = Math.min(totalPages, page + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? 'activeeee' : ''}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };



  const handlePageClick = async (pageNumber) => {
    await setleads([])
    await window.scrollTo(0, 0);
    // await getkits(pageNumber)
    await getleads(pageNumber)
    await renderPageNumbers()
  };


  const passtime = (date) => {
    const givenDate = new Date(date);
    const currentDate = new Date();

    const diffInMilliseconds = currentDate - givenDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    let response = "";

    if (diffInYears > 0) {
      response += `${diffInYears} year${diffInYears > 1 ? "s" : ""} `;
    }
    if (diffInMonths % 12 > 0) {
      response += `${diffInMonths % 12} month${diffInMonths % 12 > 1 ? "s" : ""} `;
    }
    if (diffInDays % 30 > 0) {
      response += `${diffInDays % 30} day${diffInDays % 30 > 1 ? "s" : ""}`;
    }

    return response.trim() ? `${response.trim()} ago` : "Registered today";
  };



  const getdate = (timestamp) => {
    if (!timestamp) return "Invalid date"; // Handle undefined/null input

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) return "Invalid date"; // Handle invalid timestamps

    // Format the date as dd/mm/yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };






  const getleadstodownload = async () => {
    const hide = message.loading("Action in progress", 0);

    try {
      const data = JSON.stringify({
        source: filtersource, // Ensure source list is set correctly
        register: filterregister,
        type: "converted",
      });

      const response = await axios.post(apiurlforbackend + "/getleadsbypagetodownload", data, {
        headers: { "Content-Type": "application/json" },
      });

      // Ensure data exists
      if (!response.data || !response.data.data || response.data.data.length === 0) {
        throw new Error("No leads found to download.");
      }

      let headers = ["Name", "Email", "Phone", "Become a Lead", "Date", "Source"]; // Define headers
      let data2 = response.data.data.map(lead => [lead.Name, lead.Email, lead.Phone, passtime(lead.timestamp), getdate(lead.timestamp), lead.src]); // Extract relevant data

      const csvData = [
        headers, // Header row
        ...data2 // Data rows
      ];

      // Convert array to CSV string
      const csvRows = csvData.map(row => row.join(",")).join("\n");

      // Create a Blob from the CSV string
      const blob = new Blob([csvRows], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Create a URL for the Blob and set it as the href attribute
      link.href = URL.createObjectURL(blob);

      // Set the download attribute with a filename
      link.download = `Leads_List.csv`;

      // Append the link to the document and trigger a click to start the download
      document.body.appendChild(link);
      link.click();

      // Remove the link after the download
      document.body.removeChild(link);

      console.log("Leads downloaded successfully:", response.data.data);
    } catch (error) {
      console.error("Error fetching leads:", error.message);
      message.error(`Failed to download leads: ${error.message}`);
    }

    setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 10);
  };

  const [popupdetails, setpopupdetails] = useState(false)









  const [addnoteform, setaddnoteform] = useState(false)

  const [showwhiteboard, setshowwhiteboard] = useState(false)


  const [addnote, setaddnote] = useState("")
  const [addstickynotetitle, setaddstickynotetitle] = useState("")
  const [addstickynotecontent, setaddstickynotecontent] = useState("")
  const [loading6, setloading6] = useState(false)


  const [addstickynoteform, setaddstickynoteform] = useState(false)


  const DateComponent = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year} ${hours}-${minutes}`;


    return formattedDate


  };





  const getleadbysearch = async (e) => {
    e.preventDefault()

    setleads([])
    setsearchdone(true)
    try {
      const data = JSON.stringify({
        source: filtersource, // Ensure source list is set correctly
        register: filterregister,
        limit: filterlimit,
        type: "converted",
        search: searchTerm
      });

      const response = await axios.post(apiurlforbackend + "/getleadsbysearch", data, {
        headers: { "Content-Type": "application/json" },
      });


      setleads(response.data["data"])
      setpage(response.data["current page"])

      settotalPages(response.data["total pages"])
      settotal(response.data["total leads"])

    } catch (error) {
      console.log("Error fetching leads:", error);
    }
  };

  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>




        <Navbar />

        <div className='deshboardmain'>
          <Sidemanu />

          <div className='mainbody'>



            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><SiGoogleadsense style={{ width: '30px', height: '30px' }} />Converted Leads</h1>


              {/* {dooption()} */}

              {searchdone === false && <>
                <form
                  onSubmit={getleadbysearch}
                >
                  <AutoComplete

                    style={{ width: 200 }}
                    // options={options}
                    placeholder="Search by Email/Name"
                    // filterOption={(inputValue, options) =>
                    //   options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    //   //  console.log(kitss) 
                    // }
                    onChange={(inputValue) => setSearchTerm(inputValue)}
                  />
                  <button style={{ borderRadius: '0 0 0 0', marginRight: '5px' }}>Search</button>
                  <button type='button' onClick={showDrawer}>Filter <FaFilter /></button>
                </form>

              </>}
              {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={() => clearsearch()}><AiOutlineClose /> Clear</button>  </div>
              </>}

            </div>

            <Drawer title="Set Filter" onClose={onClose} open={open}>
              <form onSubmit={async (e) => {

                e.preventDefault()
                setleads([])
                onClose()

                await getleads(1)

              }}>

                <h3>Registeration</h3>
                <div>
                  <input
                    type="radio"
                    name="registeration"
                    value="All"
                    checked={filterregister === "All"}
                    onChange={(e) => setfilterregister(e.target.value)}
                  />
                  <label>All</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="registeration"
                    value="Register 7 days ago"
                    checked={filterregister === "Register 7 days ago"}
                    onChange={(e) => setfilterregister(e.target.value)}
                  />
                  <label>Register 7 days ago</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="registeration"
                    value="Register 30 days ago"
                    checked={filterregister === "Register 30 days ago"}
                    onChange={(e) => setfilterregister(e.target.value)}
                  />
                  <label>Register 30 days ago</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="registeration"
                    value="Register 3 months ago"
                    checked={filterregister === "Register 3 months ago"}
                    onChange={(e) => setfilterregister(e.target.value)}
                  />
                  <label>Register 3 months ago</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="registeration"
                    value="Register 6 months ago"
                    checked={filterregister === "Register 6 months ago"}
                    onChange={(e) => setfilterregister(e.target.value)}
                  />
                  <label>Register 6 months ago</label>
                </div>





                <h3>Source</h3>


                {filtersourcetoshow.length > 0 && <>
                  {filtersourcetoshow.map((value) => <>
                    <div className="sourcecheckbox">
                      <input
                        id={value} // Use `id` to match with `label`
                        name={value}
                        type="checkbox"
                        checked={filtersource.includes(value)}
                        onChange={(e) => {
                          setfiltersource((prevFilters) =>
                            e.target.checked
                              ? [...prevFilters, value] // Add value when checked
                              : prevFilters.filter((item) => item !== value) // Remove value when unchecked
                          );
                        }}
                      />
                      <label htmlFor={value}>{value}</label> {/* Use `htmlFor` instead of `for` */}
                    </div>

                  </>)}

                </>}
                <button className='button' type='submit'>APPLY</button>
              </form>
            </Drawer>



            <div className='header'>
              <div style={{ display: 'flex', }}>
                Total: {total}
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>

                <button
                  onClick={() => setpopup2(true)}

                  className='button' style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><FaRegAddressCard style={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />New Entry</button>


                <button
                  onClick={() => setpopup4(true)}

                  className='button' style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><ImUpload2 style={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />Upload CSV File</button>
                <button
                  onClick={() => getleadstodownload()}

                  className='button' style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><FaDownload style={{ width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />Download CSV File</button>


              </div>


            </div>
            {popup === true && <>
              <div onClick={() => {
                setpopup(false)


              }
              } className='popupbg'></div>
              <div className='popup'>


                <div className='header' >
                  <h2>Lead Info</h2> <button onClick={() => {
                    setpopup(false)

                    setpopupdetails(true)
                  }} className="button">White Board</button>
                </div>

                <div className='kitdetailsmain' >

                  <div className='kitdetails'>
                    <div className='leadinforhead'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Name  :  </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Name} </h3>
                    </div>
                    <div className='leadinforhead'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email : </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Email} </h3>
                    </div>

                  </div>
                  {(leadsinfo.Phone && leadsinfo.Address) && <>
                    <div className='kitdetails'>

                      {leadsinfo.Phone && <>
                        <div className='leadinforhead'>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Phone Number  :  </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Phone} </h3>
                        </div>
                      </>}
                      {leadsinfo.Address && <>
                        <div className='leadinforhead'>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Address : </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Address} </h3>
                        </div>
                      </>}
                    </div>

                  </>}

                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegClock style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Become a lead</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {passtime(leadsinfo.timestamp)}

                  </h3>
                  </div>

                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Source</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    {leadsinfo.src}

                  </h3>
                  </div>

                  <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <AiOutlineInteraction style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>


                    <Dropdown menu={{ items: items1 }} placement="bottomRight" arrow>
                      <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                    </Dropdown>


                  </h3>
                  </div>








                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '98.5%', marginTop: '30px' }}>

                    <Popconfirm
                      title="Delete this Lead"
                      description="Are you sure to delete this Lead?"
                      onConfirm={async (e) => {
                        e.preventDefault()



                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", leadsinfo._id);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };

                        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltleas", requestOptions)
                          .then(response => response.json())
                          .then(result => {

                            getleads(page)
                          })
                          .catch(error => console.log('error', error));



                        setpopup(false)
                      }}

                      okText="Yes"
                      cancelText="No"
                    >

                      <a onClick={async (e) => {
                        e.preventDefault()


                      }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillDelete style={{ width: '20px', height: '20px' }} />Remove this Lead</a>

                    </Popconfirm>
                  </div>
















                </div>
                {(leadsinfo.logs && leadsinfo.logs.length > 0) ? (<>
                  <div className='header' style={{ marginTop: '20px' }}>
                    <h3>Lead Logs</h3>
                  </div>
                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"} >

                    <thead className='tablephead'>
                      <tr>
                        <th>Action</th>

                        <th>Subject</th>
                        <th>Date and Time </th>




                      </tr>
                    </thead>
                    <tbody>
                      {leadsinfo.logs.map((value) => {


                        return (<>
                          <tr>

                            <td>{value.action}</td>
                            <td>{value.subject}</td>
                            <td>{value.date} {value.time}</td>

                          </tr>
                        </>)
                      })}
                    </tbody>
                  </table>

                </>) : (<><p>No Logs</p></>)}

                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup(false)

                  }
                  } style={{ border: '1px solid red', color: 'black' }} >Close</button>






                </div>

              </div>
            </>}



            {popup4 === true && <>
              <div onClick={() => {
                setpopup4(false)
                setCSVFile(null)
              }
              } className='popupbg'></div>
              <div className='popup'>


                <p style={{ textAlign: 'left' }}><strong>Note:</strong> The uploaded CSV file must contain a proper header with the following fields: <strong>Name, Email, Address, </strong>and <strong>Phone Number</strong>. Ensure that there are no leading or trailing spaces in the header fields. The header row should be the first row in the file. Additionally, the file name will be used as the source of the lead.</p>
                <div className="file-drop-zone"


                  onDrop={handleFileDrop}
                  onDragOver={(e) => e.preventDefault()}

                >

                  <label className="custom-file-upload" >

                    <div className='clickablediv'>


                      <ImUpload2 style={{ width: '100px', height: '100px' }} />
                      <h4>Click or drag file to this area to upload</h4>


                    </div>
                    <input
                      multiple="off"
                      ref={fileInputRef}
                      className='inputbuttontouploadfile'
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />

                  </label>

                </div>

                <div className='filename'> {csvFile && (<> <p>Selected CSV File: {csvFile.name}</p><AiFillDelete onClick={handleClearInput} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></>)}</div>





                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup4(false)
                    setCSVFile(null)
                  }
                  } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                  <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>




                </div>

              </div>
            </>}



            {popup2 === true && <>
              <div onClick={() => {
                setpopup2(false)


              }
              } className='popupbg'></div>
              <div className='popup'>


                <div className='header' >
                  <h2>Enter Details To Add Lead</h2>
                </div>
                <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={senddata}>
                  <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label>Name :</label>
                    <input
                      name="name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label>Email :</label>
                    <input
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label>Phone Number :</label>
                    <input
                      name="phone"

                      value={formData.phone1}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>



                  <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label>Address :</label>
                    <input
                      name="address"

                      value={formData.address}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label>Message :</label>
                    <textarea
                      name="message"

                      value={formData.message}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                  </div>


                  <div className='bownpopupbutton' style={{ width: '80%' }}>
                    <button onClick={() => {
                      setpopup2(false)

                    }
                    } style={{ border: '1px solid red', color: 'red', backgroundColor: '#D3D3D3' }} >Close</button>


                    <button className='button'>SEND</button>



                  </div>
                </form>
              </div>
            </>}




            {popup3 === true && <>
              <div onClick={() => {
                setpopup3(false)


              }
              } className='popupbg'></div>
              <div className='popup'>

                {action === "email" && <>
                  <div className='header' >
                    <h2>SEND EMAIL</h2>
                  </div>


                  <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={sendemail}>


                    <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                      <label>Email Options :</label>


                      <select
                        name='option'
                        value={formDataemail.option}
                        onChange={handleChangeemail}
                        style={{ width: '105%' }}>
                        <option value="Send YGM Brand Email">Send YGM Brand Email</option>
                        <option value="Send Simple Email">Send Simple Email</option>
                        <option value="Email Sent Manually">Email Sent Manually</option>
                      </select>
                    </div>


                    <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                      <label>Subject :</label>
                      <input
                        name="subject"
                        required
                        value={formDataemail.subject}
                        onChange={handleChangeemail}
                        style={{ width: "100%" }}
                      />
                    </div>

                    {formDataemail.option !== "Email Sent Manually" && <>
                      <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                        <label>Email Content :</label>
                        <textarea
                          name="content"
                          required={formDataemail.option !== "Email Sent Manually"} // Set required attribute conditionally
                          value={formDataemail.content}
                          onChange={handleChangeemail}
                          style={{ width: "100%" }}
                        />

                      </div>

                    </>}



                    <div className='bownpopupbutton' style={{ width: '80%' }}>
                      <button onClick={() => {
                        setpopup3(false)

                      }
                      } style={{ border: '1px solid red', color: 'red', backgroundColor: '#D3D3D3' }} >Close</button>


                      <button className='button'>SEND</button>



                    </div>
                  </form>

                </>}

                {action === "Meeting" && <>
                  <div className='header' >
                    <h2>Enter Meeting Subject</h2>
                  </div>

                  <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={sendcallandmeet}>
                    <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                      <label>Subject :</label>
                      <input
                        name="subject"
                        required
                        value={formDatacallandmeet.subject}
                        onChange={handleChangecallandmeet}
                        style={{ width: "100%" }}
                      />
                    </div>




                    <div className='bownpopupbutton' style={{ width: '80%' }}>
                      <button onClick={() => {
                        setpopup3(false)

                      }
                      } style={{ border: '1px solid red', color: 'red', backgroundColor: '#D3D3D3' }} >Close</button>


                      <button className='button'>SEND</button>



                    </div>
                  </form>

                </>}

                {action === "Call" && <>
                  <div className='header' >
                    <h2>Enter Call Subject</h2>
                  </div>


                  <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={sendcallandmeet}>
                    <div style={{ width: "80%", display: "flex", flexDirection: "column", textAlign: "left" }}>
                      <label>Subject :</label>
                      <input
                        name="subject"
                        required
                        value={formDatacallandmeet.subject}
                        onChange={handleChangecallandmeet}
                        style={{ width: "100%" }}
                      />
                    </div>




                    <div className='bownpopupbutton' style={{ width: '80%' }}>
                      <button onClick={() => {
                        setpopup3(false)

                      }
                      } style={{ border: '1px solid red', color: 'red', backgroundColor: '#D3D3D3' }} >Close</button>


                      <button className='button'>SEND</button>



                    </div>
                  </form>

                </>}
              </div>
            </>}





            {popupdetails === true && <>
              <div onClick={() => {
                setpopupdetails(false)

                setaddstickynoteform(false)
                setaddstickynotetitle("")
                setaddstickynotecontent("")




              }
              } className='popupbg'></div>
              <div className='popup' style={{ height: '600px !important', backgroundColor: '#fff' }}>


                <>




                  <Button danger onClick={() => {

                    setaddstickynoteform(false)
                    setaddstickynotetitle("")
                    setaddstickynotecontent("")
                    setpopup(true)
                    setpopupdetails(false)




                  }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Close </Button>






                  <div className='whiteboard'>


                    <ul>


                      {leadsinfo.notes && <>
                        {leadsinfo.notes.map((value, index) => {
                          return (<>

                            <li>
                              <a href="#" contenteditable>
                                <img width={"35px"} src="/pin.png" alt='' />
                                <h2>{value.title}</h2>
                                <p>{value.content}</p>
                                <div className='notesby'>By: {value.by}</div>
                                <div className='dateeeeeee'>Date:  {value.date}</div>

                              </a>


                            </li>
                          </>)
                        })}

                      </>}



                      <li>
                        <a contenteditable>
                          <img width={"35px"} src="/pin.png" alt='' />

                          {addstickynoteform ? (<>
                            {loading6 ? (<>

                              <h2>  <img alt='' src='/empty.gif' width={"100px"} /></h2>

                            </>) : (<>
                              <form onSubmit={async (e) => {

                                e.preventDefault()
                                await setloading6(true)
                                const note = {
                                  title: addstickynotetitle,
                                  content: addstickynotecontent,
                                  date: DateComponent(),
                                  by: loginname,
                                }


                                let data = JSON.stringify({
                                  "_id": leadsinfo._id,
                                  "note": note,

                                });

                                let config = {
                                  method: 'post',
                                  maxBodyLength: Infinity,
                                  url: apiurlforbackend + '/updatestickynotetolead',
                                  headers: {
                                    'Content-Type': 'application/json'
                                  },
                                  data: data
                                };

                                await axios.request(config)
                                  .then((response) => {
                                    setleadsinfo(response.data)
                                    {
                                      setaddstickynoteform(false)
                                      setaddstickynotetitle("")
                                      setaddstickynotecontent("")
                                    }

                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });


                                await setloading6(false)



                              }} style={{ display: 'block' }}>
                                <h2><input value={addstickynotetitle} onChange={(e) => setaddstickynotetitle(e.target.value)} required className='editfield2' placeholder='Enter note Title' /></h2>
                                <textarea value={addstickynotecontent} onChange={(e) => setaddstickynotecontent(e.target.value)} required placeholder='Enter note content' className='editfield' />

                                <div className='notesby'><button className='button' style={{ width: 'fit-content', color: "red", background: "none", border: "1px solid red" }} onClick={() => {
                                  setaddstickynoteform(false)
                                  setaddstickynotetitle("")
                                  setaddstickynotecontent("")
                                }}>CANCEL</button> <button style={{ width: 'fit-content' }} className='button' type='submit'>ADD</button></div>
                              </form>
                            </>)}
                          </>) : (<>
                            <h2>ADD NOTE</h2>
                            <p><TiPlusOutline onClick={() => setaddstickynoteform(true)} style={{ width: "6em", height: '6em', opacity: "0.5", cursor: 'pointer' }} /></p>
                            <div className='notesby'>By: {loginname}</div>
                            <div className='dateeeeeee'>Date:  {DateComponent()}</div>

                          </>)}



                        </a>


                      </li>




                    </ul>

                  </div>

                </>
              </div>
            </>}



            {/* {seequetion ? (<> */}
            {/* <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = false
                    if (!practitioner.map(prac => prac.email.toLowerCase()).includes(value.Email.toLowerCase())) {
                      show = true;
                    }




                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno1++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table> */}
            {/* </>) : (<> */}



            {/* <h2 style={{ color: '#4180B7' }}> Converted Leads Within the Last 7 Days </h2>
              <div className='imp'>


                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>
                      <th>Email </th>

                      <th>Source</th>


                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {leads.map((value) => {
                      let show = false
                      const today = new Date(); // Get the current date
                      const sevenDaysAgo = new Date(today);
                      sevenDaysAgo.setDate(today.getDate() - 7); // Calculate the date 7 days ago

                      // Check if there's a practitioner with the matching email and recent timestamp
                      const practitionerWithMatch = practitioner.find(prac => {
                        return prac.email.toLowerCase() === value.Email.toLowerCase() &&
                          new Date(prac.timestamp) >= sevenDaysAgo &&
                          new Date(prac.timestamp) <= today;
                      });

                      if (practitionerWithMatch) {
                        show = true; // Set 'show' to true if a practitioner matches both conditions
                      }




                      return (<>
                        {show && <>
                          <tr>

                            <td>{sno2++}</td>
                            <td>{value.Name}</td>
                            <td>{value.Email}</td>

                            <td>{value.src}</td>



                            <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                              <button className='button'
                                onClick={() => {
                                  setpopup(true)
                                  setleadsinfo(value)

                                }}

                              > Details</button>



                            </td>


                          </tr>

                        </>}

                      </>)
                    })}












                  </tbody>
                </table>
              </div> */}



            {/* <h2 style={{ color: '#4180B7' }}>Converted Leads Between 7 Days and 30 Days  </h2>
              <div className='imp'>


                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>
                      <th>Email </th>

                      <th>Source</th>


                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {leads.map((value) => {
                      let show = false
                      const today = new Date(); // Get the current date
                      const sevenDaysAgo = new Date(today);
                      sevenDaysAgo.setDate(today.getDate() - 7); // Calculate the date 7 days ago
                      const thirtyDaysAgo = new Date(today);
                      thirtyDaysAgo.setDate(today.getDate() - 30); // Calculate the date 30 days ago

                      // Check if there's a practitioner with the matching email and a timestamp between 7 to 30 days ago
                      const practitionerWithMatch = practitioner.find(prac => {
                        const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                        return (
                          prac.email.toLowerCase() === value.Email.toLowerCase() &&
                          timestampDate >= thirtyDaysAgo &&
                          timestampDate < sevenDaysAgo
                        );
                      });

                      if (practitionerWithMatch) {
                        show = true; // Set 'show' to true if a practitioner matches both conditions
                      }



                      return (<>
                        {show && <>
                          <tr>

                            <td>{sno2a++}</td>
                            <td>{value.Name}</td>
                            <td>{value.Email}</td>

                            <td>{value.src}</td>



                            <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                              <button className='button'
                                onClick={() => {
                                  setpopup(true)
                                  setleadsinfo(value)

                                }}

                              > Details</button>



                            </td>


                          </tr>

                        </>}

                      </>)
                    })}












                  </tbody>
                </table>
              </div> */}



            {/* <h2 style={{ color: '#4180B7' }}>Converted Leads Between 30 Days and 3 Months  </h2>
              <div className='imp'>


                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>
                      <th>Email </th>

                      <th>Source</th>


                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {leads.map((value) => {
                      let show = false
                      const today = new Date(); // Get the current date
                      const thirtyDaysAgo = new Date(today);
                      thirtyDaysAgo.setDate(today.getDate() - 30); // Calculate the date 30 days ago
                      const threeMonthsAgo = new Date(today);
                      threeMonthsAgo.setMonth(today.getMonth() - 3); // Calculate the date 3 months ago

                      // Check if there's a practitioner with the matching email and a timestamp between 30 days to 3 months ago
                      const practitionerWithMatch = practitioner.find(prac => {
                        const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                        return (
                          prac.email.toLowerCase() === value.Email.toLowerCase() &&
                          timestampDate >= threeMonthsAgo &&
                          timestampDate < thirtyDaysAgo
                        );
                      });

                      if (practitionerWithMatch) {
                        show = true; // Set 'show' to true if a practitioner matches both conditions
                      }



                      return (<>
                        {show && <>
                          <tr>

                            <td>{sno2b++}</td>
                            <td>{value.Name}</td>
                            <td>{value.Email}</td>

                            <td>{value.src}</td>



                            <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                              <button className='button'
                                onClick={() => {
                                  setpopup(true)
                                  setleadsinfo(value)

                                }}

                              > Details</button>



                            </td>


                          </tr>

                        </>}

                      </>)
                    })}












                  </tbody>
                </table>
              </div> */}



            {/* <h2 style={{ color: '#4180B7' }}>Converted  Leads Older Than 3 Months  </h2>
              <div className='imp'>


                <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>
                      <th>Email </th>

                      <th>Source</th>


                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody>




                    {leads.map((value) => {
                      let show = false
                      const today = new Date(); // Get the current date
                      const threeMonthsAgo = new Date(today);
                      threeMonthsAgo.setMonth(today.getMonth() - 3); // Calculate the date 3 months ago

                      // Check if there's a practitioner with the matching email and a timestamp older than 3 months
                      const practitionerWithMatch = practitioner.find(prac => {
                        const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                        return (
                          prac.email.toLowerCase() === value.Email.toLowerCase() &&
                          timestampDate < threeMonthsAgo
                        );
                      });

                      if (practitionerWithMatch) {
                        show = true; // Set 'show' to true if a practitioner matches both conditions
                      }




                      return (<>
                        {show && <>
                          <tr>

                            <td>{sno2c++}</td>
                            <td>{value.Name}</td>
                            <td>{value.Email}</td>

                            <td>{value.src}</td>



                            <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                              <button className='button'
                                onClick={() => {
                                  setpopup(true)
                                  setleadsinfo(value)

                                }}

                              > Details</button>



                            </td>


                          </tr>

                        </>}

                      </>)
                    })}












                  </tbody>
                </table>
              </div> */}




            {/* </>)} */}




            {leads.length > 0 && <>

              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px', fontSize: "small" }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>
                    <th>Become a Lead </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = true
                    // if (!practitioner.map(prac => prac.email.toLowerCase()).includes(value.Email.toLowerCase())) {
                    //   show = true;
                    // }




                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno1++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>
                          <td>{passtime(value.timestamp)}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table>
            </>}


            {leads.length === 0 && <>
              {totalPages === 0 ? (<>
                <img alt='' src='/empty2.gif' width={"35%"} />
              </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
            </>}
            <div className="pagination">
              {page > 1 && (
                <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
              )}
              {renderPageNumbers()}
              {page < totalPages && (
                <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
              )}
            </div>
          </div>

        </div>


      </>}
    </div>
  )
}

export default Leadscoverted
