import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
// import 'antd/dist/reset.css'; // Import Ant Design styles

import Index from "./pages";
import Desboard from "./pages/desboard";
import Adminkit from "./pages/adminkit";
import Adminreport from "./pages/adminreport";
import Practitioner from "./pages/Practitioner";
import Profile from "./pages/Profile";
import Viewreport from "./pages/viewreport";
import Viewreport2 from "./pages/viewreport2";
import { useNavigate } from "react-router-dom";

import Microkit from "./pages/microkit";

import Micropre from "./pages/Micropre";

import Pracstate from "./pages/pracstates";
import Orderlables from "./pages/orderlables";
import Ackpage from "./pages/ackpage";
import Bucket from "./pages/dropresult";
import Shippingform from "./pages/shippingform";
import Shipping from "./pages/shipping";
import KitAnalysis from "./pages/KitAnalysis";
import Kitregistration from "./pages/kitregistration";
import Helpcenter from "./pages/helpcenter";
import Tickets from "./pages/tickets";
import Ticketsid from "./pages/ticketsid";
import Webinar from "./pages/Webinar";
import Webinardetails from "./pages/Webinardetails";
import Meeting from "./pages/meeting";
import Eventinfoform from "./pages/eventinfoform";
import Eventinfo from "./pages/eventinfo";
import Maps from "./pages/maps";
import Dnamapform from "./pages/Dnamapform";
import Praform from "./pages/praform";
import Leads from "./pages/Leads";
import Orderstates from "./pages/orderstates";
import Pararesults from "./pages/pararesults";

import Meetings from "./pages/meetings";
import Meetingmeet from "./pages/meetingmeet";
import Fsform from "./pages/fsform";
import Fsformdata from "./pages/foodsform";
import Meetingsusers from "./pages/meetingsusers";
import Sendemail from "./pages/sendemail";

import Candidaform from "./pages/candidaform";
import Candidaformdata from "./pages/candidaformdata";
import Leadsstate from "./pages/Leadsstates";
import Meetingsdetails from "./pages/meetingsdetails";

import KITS from "./components/kits";
import KitReport from "./components/report";
import Practitionerpage from "./components/practitioners";
import Fsformdatawl from "./pages/foodsformwl";
import Teamticks from "./pages/teams tickets/teamticks";
import Teamticketsdetails from "./pages/teams tickets/teamticketsdetails";
import NotLogin from "./components/notLogin";
import Leadscoverted from './pages/Leadscoverted';
import Forms from "./components/forms";
import Formview from "./components/formsview";
import Singalemail from "./pages/emails/singalemail";
import Massemail from "./pages/emails/massemail";
import Emailreport from "./pages/emails/emailreport";
import Emailtemplates from "./pages/emails/emailtemplates";





// view kits 
const Fskits = () => <KITS kittype="Food Sensitivtiy 100" />;
const Fskits210 = () => <KITS kittype="Food Sensitivtiy 210" />;
const Fskitsmap = () => <KITS kittype="FoodSensitivityMap" />;
const Parasitologykit = () => <KITS kittype="Parasitology Test" />;
const Candidakit = () => <KITS kittype="CandidaProfile" />;
const DNAkit = () => <KITS kittype="DNAMap Genetic Test" />;
const MicrobiomeAdvanced = () => <KITS kittype="MicrobiomeAdvanced" />;
const Microbiomeplus = () => <KITS kittype="MicrobiomePlus" />;


// view report 
const Fs100report = () => <KitReport kittype="Food Sensitivtiy 100" />;
const Fs210report = () => <KitReport kittype="Food Sensitivtiy 210" />;
const Fsmapreport = () => <KitReport kittype="FoodSensitivityMap" />;
const Parasitologykitreport = () => <KitReport kittype="Parasitology Test" />;
const Candidakitreport = () => <KitReport kittype="CandidaProfile" />;
const Microbiomeplusreport = () => <KitReport kittype="MicrobiomePlus" />;
const MicrobiomeAdvancedreport = () => <KitReport kittype="MicrobiomeAdvanced" />;
const DNAkitreport = () => <KitReport kittype="DNAMap Genetic Test" />;


// view Practitioner 
const Fs100prac = () => <Practitionerpage kittype="Food Sensitivtiy 100" />;
const Fs210prac = () => <Practitionerpage kittype="Food Sensitivtiy 210" />;
const Fsmapprac = () => <Practitionerpage kittype="FoodSensitivityMap" />;
const Parasitologykitprac = () => <Practitionerpage kittype="Parasitology Test" />;
const Candidakitprac = () => <Practitionerpage kittype="CandidaProfile" />;
const Microbiomeplusprac = () => <Practitionerpage kittype="MicrobiomePlus" />;
const MicrobiomeAdvancedprac = () => <Practitionerpage kittype="MicrobiomeAdvanced" />;
const DNAkitprac = () => <Practitionerpage kittype="DNAMap Genetic Test" />;


// fill form 
const FSFORM = () => <Forms kittype="FoodSensitivityMap" />;
const CANDIDAFORM = () => <Forms kittype="CandidaProfile" />;


const FSFORMview = () => <Formview kittype="FoodSensitivityMap" formtype="normal" />;
const CANDIDAFORMview = () => <Formview kittype="CandidaProfile" formtype="normal" />;
const ParasitologyFORMview = () => <Formview kittype="Parasitology Test" formtype="normal" />;
const DNAMapFORMview = () => <Formview kittype="DNAMap Genetic Test" formtype="normal" />;


const FSFORMview_wl = () => <Formview kittype="FoodSensitivityMap" formtype="white-label" />;
const CANDIDAFORMview_wl = () => <Formview kittype="CandidaProfile" formtype="white-label" />;
const ParasitologyFORMview_wl = () => <Formview kittype="Parasitology Test" formtype="white-label" />;
const DNAMapFORMview_wl = () => <Formview kittype="DNAMap Genetic Test" formtype="white-label" />;


function ScrollToTop() {
  const { pathname } = useLocation();
  const loginemail = Cookies.get("email");
  const loginname = Cookies.get("Name");
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        "https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfocheck",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result === "deactivated") {
            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate("/");
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [pathname]);

  return null;
}

const Report = () => {
  const { id } = useParams();

  const getinfo = () => {
    let data = JSON.stringify({
      id: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + "/getidandtype",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const CNAME = response.data.data.Kittype.toLowerCase().replace(
          /\s+/g,
          ""
        );
        const urltothrough = `https://${CNAME}-report.yourgutmap.co.uk/${response.data.data._id}`;
        window.location.href = urltothrough;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getinfo();
  }, []);
};

function App() {

  const definedRoutes = [
    "/",
    "/report/:id",
    "/kitack",
    "/shipping",
    "/leads",
    "/leads-state",
    "/eventinfo",
    "/bucket",
    "/dashboard",
    "/dashboard/analysis",
    "/dashboard/webinar",
    "/dashboard/webinar/:id",
    "/dashboard/webinar/meeting/:id",
    "/dashboard/helpcenter",
    "/dashboard/tickets",
    "/dashboard/order-states",
    "/dashboard/map",
    "/dashboard/meet",
    "/dashboard/send-emails",
    "/dashboard/meet-user",
    "/dashboard/meet-user/:id",
    "/dashboard/meet/:id",
    "/dashboard/tickets/:id",
    "/dashboard/kitregistration",
    "/dashboard/para-report/:id",
    "/dashboard/view-report/:id",
    "/dashboard/view-report100/:id",
    "/dashboard/teams/tickets",
    "/dashboard/teams/tickets/:id",
    "/dashboard/fs100/kit",
    "/dashboard/fs210/kit",
    "/dashboard/fs100/report",
    "/dashboard/fs210/report",
    "/dashboard/uploadreport",
    "/dashboard/fs210/viewreport/:id",
    "/dashboard/fs100/practitioner",
    "/dashboard/fs210/practitioner",
    "/foodsensitivityform",
    "/fs-Form",
    "/dashboard/fsmap/kit",
    "/dashboard/fsmap/report",
    "/dashboard/fsmap/practitioner",
    "/dashboard/microbiome/kit",
    "/dashboard/microbiome/practitioner",
    "/dashboard/microbiomeplus/kit",
    "/dashboard/microbiomeplus/report",
    "/dashboard/microbiomeplus/practitioner",
    "/dashboard/microbiomeadvanced/kit",
    "/dashboard/microbiomeadvanced/report",
    "/dashboard/microbiomeadvanced/practitioner",
    "/dashboard/dna/kit",
    "/dnamap-form",
    "/dashboard/dna/report",
    "/dashboard/dna/practitioner",
    "/dashboard/Parasitology/kit",
    "/dashboard/Parasitology/report",
    "/dashboard/Parasitology/practitioner",
    "/Parasitology-Test-Form",
    "/dashboard/candidaprofile/kit",
    "/dashboard/candidaprofile/report",
    "/dashboard/candidaprofile/practitioner",
    "/candidaform",
    "/foodsensitivityform-view",
    "/candidaform-view",
    "/dnamapform-view",
    "/parasitologyform-view",
    "/dashboard/kit",
    "/dashboard/practitioner",
    "/dashboard/practitionerstates",
    "/dashboard/orders",
    "/dashboard/shippingdata",
    "/dashboard/eventinfogdata",
    "/dashboard/profile",
    "/dashboard/send-email",
    "/dashboard/send-mass-email",
    "/dashboard/email-reports",
    "/dashboard/email-templates"
  ];

  function NotFound() {
    return (
      <div className="" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <img src="/page not found.gif" width="20%" alt="Page Not Found" />
      </div>
    );
  }




  function AppRouter() {
    const location = useLocation();
    const isValidRoute = definedRoutes.some((route) => {
      const dynamicRoute = route.replace(/:\w+/g, ".*"); // Convert dynamic params into regex
      const regex = new RegExp(`^${dynamicRoute}$`);
      return regex.test(location.pathname);
    });

    return (
      <Routes>
        {!isValidRoute && <Route path="*" element={<NotFound />} />}
      </Routes>

    );
  }





  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/report/:id" element={<Report />} />
          <Route path="/kitack" element={<Ackpage />} />
          <Route path="/shipping" element={<Shippingform />} />
          <Route path="/leads" element={<Leads />} />
          <Route path='/leads-converted' element={<Leadscoverted />} />
          <Route path="/leads-state" element={<Leadsstate />} />
          <Route path="/dashboard/uploadreport" element={<Adminreport />} />

          <Route path="/eventinfo" element={<Eventinfoform />} />
          <Route path="/bucket" element={<Bucket />} />
          <Route path="/dashboard" element={<Desboard />} />
          <Route path="/dashboard/analysis" element={<KitAnalysis />} />
          <Route path="/dashboard/webinar" element={<Webinar />} />
          <Route path="/dashboard/webinar/:id" element={<Webinardetails />} />
          <Route path="/dashboard/webinar/meeting/:id" element={<Meeting />} />
          <Route path="/dashboard/helpcenter" element={<Helpcenter />} />
          <Route path="/dashboard/tickets" element={<Tickets />} />
          <Route path="/dashboard/order-states" element={<Orderstates />} />
          <Route path="/dashboard/map" element={<Maps />} />
          <Route path="/dashboard/meet" element={<Meetings />} />
          <Route path="/dashboard/send-emails" element={<Sendemail />} />
          <Route path="/dashboard/meet-user" element={<Meetingsusers />} />
          <Route path="/dashboard/meet-user/:id" element={<Meetingsdetails />} />
          <Route path="/dashboard/meet/:id" element={<Meetingmeet />} />
          <Route path="/dashboard/tickets/:id" element={<Ticketsid />} />
          <Route path="/dashboard/kitregistration" element={<Kitregistration />} />
          <Route path="/dashboard/para-report/:id" element={<Pararesults />} />
          <Route path="/dashboard/view-report/:id" element={<Viewreport2 />} />
          <Route path="/dashboard/view-report100/:id" element={<Viewreport2 />} />

          {/* teamstickets */}
          <Route path="/dashboard/teams/tickets" element={<Teamticks />} />
          <Route path="/dashboard/teams/tickets/:id" element={<Teamticketsdetails />} />
        </Routes>

        <Routes>
          {/* fs/  */}
          <Route path="/dashboard/fs100/kit" element={<Fskits />} />
          <Route path="/dashboard/fs210/kit" element={<Fskits210 />} />
          <Route path="/dashboard/fs100/report" element={<Fs100report />} />
          <Route path="/dashboard/fs210/report" element={<Fs210report />} />
          <Route path="/dashboard/fs210/viewreport/:id" element={<Viewreport />} />
          <Route path="/dashboard/fs100/practitioner" element={<Fs100prac />} />
          <Route path="/dashboard/fs210/practitioner" element={<Fs210prac />} />
          {/* <Route path="/foodsensitivityform" element={<Fsform />} /> */}
          {/* <Route path="/foodsensitivityform-data" element={<Fsformdata />} /> */}
          <Route path="/fs-Form" element={<Fsformdatawl />} />

          {/* fsmap/  */}
          <Route path="/dashboard/fsmap/kit" element={<Fskitsmap />} />
          <Route path="/dashboard/fsmap/report" element={<Fsmapreport />} />
          <Route path="/dashboard/fsmap/practitioner" element={<Fsmapprac />} />

          {/* micro/     */}
          <Route path="/dashboard/microbiome/kit" element={<Microkit />} />
          <Route path="/dashboard/microbiome/practitioner" element={<Micropre />} />

          {/* micro plus/     */}
          <Route path="/dashboard/microbiomeplus/kit" element={<Microbiomeplus />} />
          <Route path="/dashboard/microbiomeplus/report" element={<Microbiomeplusreport />} />
          <Route path="/dashboard/microbiomeplus/practitioner" element={<Microbiomeplusprac />} />

          {/* micro adv/     */}
          <Route path="/dashboard/microbiomeadvanced/kit" element={<MicrobiomeAdvanced />} />
          <Route path="/dashboard/microbiomeadvanced/report" element={<MicrobiomeAdvancedreport />} />
          <Route path="/dashboard/microbiomeadvanced/practitioner" element={<MicrobiomeAdvancedprac />} />

          {/* dna/     */}
          <Route path="/dashboard/dna/kit" element={<DNAkit />} />
          <Route path="/dnamap-form" element={<Dnamapform />} />
          <Route path="/dashboard/dna/report" element={<DNAkitreport />} />
          <Route path="/dashboard/dna/practitioner" element={<DNAkitprac />} />

          {/* Parasitology */}
          <Route path="/dashboard/Parasitology/kit" element={<Parasitologykit />} />
          <Route path="/dashboard/Parasitology/report" element={<Parasitologykitreport />} />
          <Route path="/dashboard/Parasitology/practitioner" element={<Parasitologykitprac />} />
          <Route path="/Parasitology-Test-Form" element={<Praform />} />

          {/* condida */}
          <Route path="/dashboard/candidaprofile/kit" element={<Candidakit />} />
          <Route path="/dashboard/candidaprofile/report" element={<Candidakitreport />} />
          <Route path="/dashboard/candidaprofile/practitioner" element={<Candidakitprac />} />
          {/* <Route path="/candidaform" element={<Candidaform />} /> */}
          {/* <Route path="/candidaform-data" element={<Candidaformdata />} /> */}
          <Route path="/dashboard/kit" element={<Adminkit />} />
          <Route path="/dashboard/practitioner" element={<Practitioner />} />
          <Route path="/dashboard/practitionerstates" element={<Pracstate />} />
          <Route path="/dashboard/orders" element={<Orderlables />} />
          <Route path="/dashboard/shippingdata" element={<Shipping />} />
          <Route path="/dashboard/eventinfogdata" element={<Eventinfo />} />
          <Route path="/dashboard/profile" element={<Profile />} />

        </Routes>





        {/* form urls  */}
        <Routes>
          <Route path="/foodsensitivityform" element={<FSFORM />} />
          <Route path="/candidaform" element={<CANDIDAFORM />} />


          <Route path="/foodsensitivityform-view" element={<FSFORMview />} />
          <Route path="/candidaform-view" element={<CANDIDAFORMview />} />
          <Route path="/dnamapform-view" element={<DNAMapFORMview />} />
          <Route path="/parasitologyform-view" element={<ParasitologyFORMview />} />



          <Route path="/foodsensitivityform-view-wl" element={<FSFORMview_wl />} />
          <Route path="/candidaform-view-wl" element={<CANDIDAFORMview_wl />} />
          <Route path="/dnamapform-view-wl" element={<DNAMapFORMview_wl />} />
          <Route path="/parasitologyform-view-wl" element={<ParasitologyFORMview_wl />} />


        </Routes>






        <Routes>
        

          <Route path="/dashboard/send-email" element={<Singalemail />} />
          <Route path="/dashboard/send-mass-email" element={<Massemail />} />
          <Route path="/dashboard/email-reports" element={<Emailreport />} />
          <Route path="/dashboard/email-templatesv" element={<Emailtemplates />} />



         
        </Routes>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
