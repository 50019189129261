import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { Checkbox, message } from 'antd';
import { AiFillDelete } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGenderAndrogyne, TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown } from 'react-icons/fa6';
import { GiDna2 } from 'react-icons/gi';
import { IoFastFood } from 'react-icons/io5';
import NotLogin from '../components/notLogin';


function Fsformdatawl() {
    var sno1 = 1
    var sno2 = 1
  
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const loginemail = Cookies.get("email")
  
  
    const [popup, setpopup] = useState(false)
  
  
  
    const [orders, setorders] = useState([])
  
  
    const [orderdetails, setorderdetails] = useState([])
  
    const [kit_code, setkitcode] = useState([])
  
    const getorders = async () => {
  
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
  
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
  
        redirect: 'follow'
      };
  
      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getuploadresultotherfsformwl", requestOptions)
        .then(response => response.json())
        .then(result => setorders(result))
    };
  
  
  
    useEffect(() => {
      getorders()
  
  
    }, [])
  
  
  
  
  
  
  
  
  
  
  
  
  
    return (
      <div>
        {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53"  ?
         <>
  
  
  
          <Navbar />
  
          <div className='deshboardmain'>
            <Sidemanu />
  
            <div className='mainbody'>
  
              <div className='header'>
                <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><IoFastFood style={{ width: '50px', height: '50px' }} />White Label Food Sensitivity Form</h1>
  
  
              </div>
  
  
  
              {popup === true && <>
                <div onClick={() => {
                  setpopup(false)
  
  
                }
                } className='popupbg'></div>
                <div className='popup'>
  
  
                  <div className='header' >
                    <h2> Info</h2>
                  </div>
  
                  <div className='kitdetailsmain' >
  
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.name}  </h3>
                    </div>
  
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdEmail style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Email </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.email} </h3>
                    </div>
  
                  
  
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGenderAndrogyne style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Date of Birth</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {orderdetails.DOB} </h3>
                    </div>
  
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaBarcode style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Kit Code</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}> {orderdetails.KitCode}  </h3>
                    </div>
  
                    <div className='kitdetails'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <BsFillCalendar2DateFill style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Sample Date</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center', textAlign: 'right' }}>  {orderdetails.sampledate} </h3>
                    </div>
  
  
                
  
                    {/* <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px',margin:"0 10px" }} /> Action </h3><h3 onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
                      var urlencoded = new URLSearchParams();
                      urlencoded.append("_id", orderdetails._id);
  
                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };
  
  
                      await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dlldnamapformdata", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          setorders(result)
                          setpopup(false)
  
                        })
                        .catch(error => console.log('error', error));
  
  
  
  
  
                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      }, 2000);
  
                    }} className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Data <AiFillDelete style={{ width: '20px', height: '20px',margin:"0 10px" }} /></h3>
                    </div> */}
  
  
                  </div>
  
                  <div className='bownpopupbutton'>
                    <button onClick={() => {
                      setpopup(false)
  
                    }
                    } style={{ border: '1px solid red', color: 'black' }} >Close</button>
  
  
  
  
  
  
                  </div>
  
                </div>
              </>}
  
  
  
  
  
              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{marginTop:'20px'}}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
  
                    <th>Name</th>
                    <th>Email </th>
  
  
                    <th>Kit Code</th>
                    
                    <th>Action</th>
  
                  </tr>
                </thead>
                <tbody>
  
  
  
  
                  {orders.map((value, index) => {
  
  
  
                    return (<>
                      <tr>
  
                        <td>{sno1++}</td>
                        <td>{value.name} </td>
                        <td>{value.email}</td>
                        <td>{value.KitCode}</td>
                   
                        {/* <td style={{textAlign:'left'}}>{kit_code.map((value2, index) => (<>
                          {index + 1} - {value2}<br/>
                        </>))}</td> */}
  
  
                        <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                          <button className='button'
                            onClick={() => {
                              setpopup(true)
  
                            
                            
                            
                          
                              setorderdetails(value)
                            }}
  
                          > Details</button>
  
  
                         
                        </td>
  
  
                      </tr>
                    </>)
                  })}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
                </tbody>
              </table>
  
  
  
  
  
  
  
            </div>
  
          </div>
  
  
  
        </> : <NotLogin/> } 
      </div>
    )
  }
  
  export default Fsformdatawl
