import React from 'react'

function Emailreport() {
  return (
    <div>
      Emailreport
    </div>
  )
}

export default Emailreport
