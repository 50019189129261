import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../components/navbar';
import "../css/adminreport.css"
import Sidemanu from '../components/sidemanu';

import Cookies from 'js-cookie';

import Uploadresults from '../components/uploadresults';
import NotLogin from '../components/notLogin';


function Adminreport() {



  const token = Cookies.get("Token")
  









  return (<>
    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") ?
     <>
      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        {/* <div className='adminkitmainbody'>
          <div className='header'>
            <h1><FaUpload /> Upload Results</h1>

          </div>


          {loading2 ? (<>
           
            <h3>Logs</h3>
            <div className='showreportlogs'>
              {Message.map((value, index) => (<>
                <div
                  dangerouslySetInnerHTML={{ __html: value }}
                />



              </>))}
              {loading ? (<>
                <img alt='' src='/empty.gif' width={"5%"} />
              </>) : (<>
                <Button onClick={() => {
                  setMessages([])
                  setloading2(false)
                  setfiles([])
                }} danger>Clear</Button >
              </>)}
              <div ref={messagesEndRef} />
            </div>
          </>) : (<>

            <div className="file-drop-zone"


              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}

            >

              <label className="custom-file-upload" >

                <div className='clickablediv'>


                  <ImUpload2 style={{ width: '100px', height: '100px' }} />
                  <h4>Click or drag file to this area to upload</h4>


                </div>
                <input
                  multiple="on"
                  ref={fileInputRef}
                  className='inputbuttontouploadfile'
                  type="file"
                  accept=".csv,.pdf"
                  onChange={handleFileUpload}
                />

              </label>

            </div>


         


            {files.map((value, index2) => {

              return (<>
                <div className='filename'>  <p>{sno1++})- {value.name}</p><AiFillDelete onClick={() => { setfiles((prevFiles) => prevFiles.filter((_, index) => index !== index2)); }} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></div>
              </>)
            })}




            <div className='bownpopupbutton'>
              <button onClick={() => {
                setfiles([])
              }
              } style={{ border: '1px solid red', color: 'red' }} type='button' >CLEAR</button>






              {files.length > 0 && <>
                <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>
              </>}



            </div>
          </>)}





        </div> */}
        <Uploadresults />

      </div>



    </> : <NotLogin/> } </>)
}









export default Adminreport