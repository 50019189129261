import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import '../css/shipping.css'
import { AutoComplete, message } from 'antd';
import axios from 'axios';

function Forms(params) {

    const type = params.kittype




    const [btn, setbtn] = useState(true)
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [KitCode, setKitCode] = useState("")
    const [DOB, setDOB] = useState("")
    const [sampledate, setsampledate] = useState("")


    const uploaddata = async (e) => {

        e.preventDefault()





        await setbtn(false)

        let data = JSON.stringify({
            "name": name,
            "email": email,
            "sampledate": sampledate,
            "KitCode": KitCode,
            "DOB": DOB,
            "type": type,
            "formtype": 'normal'
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_APIURL + '/submitformdata',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));

                if (response.status === 200) {
                    setbtn(true)
                    setname("")
                    message.success(response.data.Message)
                    setemail("")
                    setKitCode("")
                    setDOB("")
                    setsampledate("")
                } else if (response.status === 202) {
                    setbtn(true)
                    message.error(response.data.Message)
                }


            })
            .catch((error) => {
                console.log(error);
            });









    }



    return (<>


        <Navbar />
        <div className='deshboardmain' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className='popup' style={{ height: 'fit-contant', backgroundColor: '#fdgh', position: 'relative', maxHeight: 'fit-content', zIndex: '0', left: "0", top: '0' }}>

                {btn ? (<>
                    <form className='shippinfform' onSubmit={uploaddata}>
                        <h1 style={{ color: '#7152A1' }} >{type} Form</h1>
                        <div className='lableee'>
                            <label>Name <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setname(e.target.value)} value={name} required placeholder='Name' />
                        </div>

                        <div className='lableee'>
                            <label>Email <span style={{ color: "red" }}>*</span></label>
                            <input onChange={(e) => setemail(e.target.value)} value={email} required placeholder='Email' />
                        </div>




                        <div className='lableee'>
                            <label>Sample Date <span style={{ color: "red" }}>*</span></label>
                            <input type='date' onChange={(e) => setsampledate(e.target.value)} value={sampledate} required placeholder='Email' />
                        </div>
                        {type === "FoodSensitivityMap" ? (<>
                            <div className='lableee testttttt'>
                                <label>Kitcode Number  <span style={{ color: "red" }}>*</span></label>
                                <input type='number' onInput={(e) => {
                                    e.target.value = e.target.value.slice(0, 14); // Restrict to 14 digits
                                }} style={{ paddingLeft: '32px' }} onChange={(e) => setKitCode(e.target.value)} value={KitCode} required placeholder='Kit-Code' />
                            </div>

                        </>) : (<>
                            <div className='lableee'>
                                <label>Kitcode Number  <span style={{ color: "red" }}>*</span></label>
                                <input onChange={(e) => setKitCode(e.target.value)} value={KitCode} required placeholder='Kit-Code' />
                            </div>
                        </>)}
                        <div className='lableee'>
                            <label>Date Of Birth  <span style={{ color: "red" }}>*</span></label>
                            <input type='date' onChange={(e) => setDOB(e.target.value)} value={DOB} required placeholder='Phone' />
                        </div>







                        <button disabled={!btn} style={{ width: "100%" }}>SEND</button>
                    </form>

                </>) : (<>
                    <img alt='' src='/empty.gif' width={"35%"} />
                </>)}

            </div>
        </div>

    </>)
}

export default Forms
